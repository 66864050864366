<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="poins">
      <HeaderPoin :poin="user.poin"/>
      <RedeemPoin id="redeem" :points="points" :poin="user.poin"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import HeaderPoin from "../components/poins/HeaderPoin";
import RedeemPoin from "../components/poins/RedeemPoin";

export default {
  name: "Poins",
  components: {RedeemPoin, HeaderPoin, Footer},
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    points() {
      return this.$store.state.point.points;
    },
    point() {
      return this.$store.state.point.point;
    }
  },
  mounted() {
    this.$store.dispatch('point/getAll', this.user.id);
    this.$store.dispatch('point/getHistory', this.user.id);
  }
}
</script>

<style scoped>
.poins {
  margin-top: 100px;
}

</style>