<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <v-container flush>
      <v-card class="pa-2">
        <v-row class="pa-10">
          <v-btn color="warning" class="ml-20" @click="$router.go(-1)">Back</v-btn> <v-btn v-if="!lunas" color="success" :loading="loading" @click="formAjukan(desserts.amortisasi.AgreementNo)" class="ml-auto mr-20">Ajukan Pelunasan</v-btn>
        </v-row>
        <v-divider></v-divider>
        <h2 class="text-center">Report Amortisasi</h2>
        <br>
        <v-row class="pa-6"  v-if="desserts.amortisasi">
          <v-col style="background-color:  rgb(239, 239, 239);" :cols="is_mobile() ? '12':'6'" md="6">
            <v-row no-gutters>
              <v-col md-6>
                <label for="">Nama</label>
              </v-col>
              <v-col md-6>
                <label for="">: {{desserts.amortisasi.EmployeeName}}</label>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col md-6>
                <label for="">NIK</label>
              </v-col>
              <v-col md-6>
                <label for="">: {{desserts.amortisasi.NIK}}</label>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col md-6>
                <label for="">Agreement No</label>
              </v-col>
              <v-col md-6>
                <label for="">: {{desserts.amortisasi.AgreementNo}}</label>
              </v-col>
            </v-row>
          </v-col>
          <v-col style="background-color: rgb(239, 239, 239);" :cols="is_mobile() ? '12':'6'" md="6">
            <v-row no-gutters>
              <v-col md-6>
                <label for="">Total Pinjaman</label>
              </v-col>
              <v-col md-6>
                <label for="">: {{toRupiah(Math.round(desserts.amortisasi.LoanAmount))}}</label>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col md-6>
                <label for="">Tenor</label>
              </v-col>
              <v-col md-6>
                <label for="">: {{desserts.amortisasi.Tenor}}</label>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col md-6>
                <label for="">Finance Purpose</label>
              </v-col>
              <v-col md-6>
                : {{desserts.amortisasi.FinancePurpose}}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr>
        <v-card-title>
          <v-responsive
            class="ml-auto"
            max-width="200"
          >
          <v-text-field
          hide-details
            dense 
            filled 
            single-line
            display:flex
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
              ></v-text-field>
            </v-responsive>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts.list"
      :search="search"
      hide-default-footer
      disable-pagination
      :loading="loading"
    loading-text="Loading... Please wait"
    >
        <template #[`item.index`]="{ item }">
          {{ desserts.list.indexOf(item) }}
        </template>
        <template #[`item.InstallmentDueDate`]="{ item }">
            {{ toDate(item.InstallmentDueDate)}}
        </template>
        <template #[`item.PrincipalAmount`]="{ item }">
            {{ toRupiah(Math.round(item.PrincipalAmount > 0 ? item.PrincipalAmount : '0'))}}
        </template>
        <template #[`item.InterestAmount`]="{ item }">
          {{ toRupiah(Math.round(item.InterestAmount > 0 ? item.InterestAmount : '0'))}}
        </template>
        <template #[`item.InstallmentAmount`]="{ item }">
          {{ toRupiah(Math.round(item.InstallmentAmount > 0 ? item.InstallmentAmount : '0'))}}
        </template>
        <template #[`item.OSPrincipalAmount`]="{ item }">
          {{ toRupiah(Math.round(item.OSPrincipalAmount > 0 ? item.OSPrincipalAmount : '0'))}}
        </template>
        <template #[`item.Status`]="{ item }">
          <v-chip v-if="desserts.list.indexOf(item) === 0"
            class="ma-2"
            small
            color="primary"
            text-color="white"
          >
            PROSES
          </v-chip>
          <v-chip v-else
            class="ma-2"
            small
            :color="item.PaidDate === null ? 'red' : 'success'"
            text-color="white"
          >
            {{ item.PaidDate === null ? 'UNPAID' : 'PAID' }}
          </v-chip>
          <!-- <v-chip
            v-if="item.PaidDate"
            small
            class="ma-2"
            color="green"
            text-color="white"
          >
          PAID
          </v-chip>
          <v-chip
            v-else
            class="ma-2"
            small
            color="red"
            text-color="white"
          >
          UNPAID
          </v-chip> -->
        </template>
    </v-data-table>
      </v-card>
    </v-container>
    <Footer/>
    <FormPelunasan/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import { mapState, mapActions } from 'vuex'
import Utils from '../utils/util.js'
import FormPelunasan from "../components/modal/FormPelunasan.vue";
export default {
  name: "ReportAmortisasi",
  components: { Footer, FormPelunasan},
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Bulan',
          sortable: false,
          value: 'index', align: 'center'
        },
        { text: 'Due Date', value: 'InstallmentDueDate', sortable: false, align: 'center' },
        { text: 'Angsuran Pokok', value: 'PrincipalAmount', sortable: false, align: 'right'},
        { text: 'Angsuran Bunga', value: 'InterestAmount', sortable: false, align: 'right' },
        { text: 'Total Angsuran', value: 'InstallmentAmount', sortable: false, align: 'right' },
        { text: 'Saldo Pinjaman', value: 'OSPrincipalAmount', sortable: false, align: 'right' },
        { text: 'Status', value: 'Status', sortable: false, align: 'center' },
      ],
      lunas: true
    }
  },
  computed: {
    ...mapState('loan', {
      desserts: (state) => state.listAmortisasi,
      loading: (state) => state.loading
    })
  },
  mounted () {
    this.$store.dispatch('loan/ListAmortisasi', this.$route.params.AgreementNo)
    setTimeout(() => {
      this.checkLunas()
    }, 1000);
  },
  methods:{
    ...mapActions('loan', [
      'ListAmortisasi','formAjukan'
    ]),
    checkLunas () {
      setTimeout(() => {
        console.log(JSON.stringify(this.desserts.list))
        const index = this.desserts.list.find(x => x.PaidDate === null)
        console.log(index);
        if (index) {
          this.lunas = false
        } else {
          this.lunas = true
        }
      }, 1000);
      
    },
    is_mobile () {
      const isMobile = window.matchMedia('only screen and (max-width: 760px)')
      return !!isMobile.matches
    },
    toRupiah (nominal) {
      return Utils.toRupiah(nominal)
    },
    toDate (nominal) {
      return Utils.toDate(nominal)
    }
  }
}
</script>

<style scoped>
.poins {
  margin-top: 100px;
}

</style>