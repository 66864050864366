<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="poins">
      <div class="container">
        <Back/>
        <div class="riwayat mt-5">
          <h1>Riwayat Penukaran Poin</h1>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-for="item in point" :key="item.id">
              <div class="poins-history-item">
                <div class="row">
                  <div class="col-2">
                    <img :src="item.ImagesPath" alt="item.id">
                  </div>
                  <div class="col-10">
                    <div class="row">
                      <div class="col-8 text-left">
                        <h6>Kupon</h6>
                        <h5>{{ item.Title }}</h5>
                      </div>
                      <div class="col-4 text-right">
                        <h6>{{ item.Status }}</h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 text-left">
                        <h6>Jumlah Poin Tertukar</h6>
                        <h5>{{ item.Value }}</h5>
                      </div>
                    </div>
                    <h6>Kode</h6>
                    <div class="row">
                      <div class="col-6 text-left">
                        <h5>{{ item.Tenor }} Bulan</h5>
                      </div>
                      <div class="col-6 text-right">
                        <h6>Berlaku {{ item.end | moment('DD-MM-YYYY') }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import Back from "../components/Back";

export default {
  name: "HistoryPoin",
  components: {Back, Footer},
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    point() {
      return this.$store.state.point.point;
    }
  },
  mounted() {
    this.$store.dispatch('point/getHistory', this.user.id);
  }
}
</script>

<style scoped>
.poins {
  margin-top: 100px;
}

.poins .container .riwayat h1{
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.poins-history {
  margin-top: 50px;
  background-color: #FFEB3B;
  padding-top: 20px;
  padding-bottom: 90px;
  min-height: 500px;
}

.poins-history-item {
  margin-top: 30px;
  mix-blend-mode: normal;
  border: 2px solid #1E0E62;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px 30px 20px;
  cursor: pointer;
}

.poins-history-item:hover {
  margin-top: 30px;
  mix-blend-mode: normal;
  border: 2px solid #FFEB3B;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px 30px 20px;
  cursor: pointer;
}

.poins-history-item img {
  width: 100px;
  padding-right: 20px;
  border-radius: 10px;
}

.poins-history-item h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  color: #1E0E62;
}

.poins-history-item h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1E0E62;
}
</style>