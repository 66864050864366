<template>
  <div class="redeem-poin text-center">
    <div class="container">
      <h3>Redeem Poin</h3>
      <h6>Penawaran terbaik dari kami</h6>
      <div class="row">
        <div v-for="point in points" :key="point.id" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6">
          <div class="centered">
            <img :src="point.ImagesPath">
            <div class="content">
              <h4>{{ point.Title }}</h4>
              <p>{{ point.Description }}</p>
              <div class="row">
                <div class="col-6">
                  <h6 class="text-left pl-4">{{ point.Value }} poin</h6>
                </div>
                <div class="col-6">
                  <button v-show="poin>0" class="btn loan-btn-primary" @click="handleRedeem(point)">Redeem</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PointService from '../../services/point.service'

export default {
  name: "RedeemPoin",
  props: {points: Array,poin:Number},
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    }
  },
  methods: {
    getPoints() {
      this.$store.dispatch('point/getAll', this.user.id);
      this.$store.dispatch('point/getHistory', this.user.id);
    },
    handleRedeem(point) {
      let params = {
        id_user: this.user.id,
        poin_id: point.id,
        value: point.Value
      }
      let loading = this.$loading.show();
      PointService.postRedeemPoint(params).then(response => {
        if (response.code === 200) {
          this.$store.dispatch('auth/refresh');
          this.getPoints();
          loading.hide();
          this.$swal.fire(
              'Sukses',
              'Selamat Penukaran point anda berhasil',
              'success',
          )
        } else {
          loading.hide();
          this.$swal.fire(
              'Error!',
              response.message,
              'error'
          )
        }
      })
    },
  },
  created() {
    this.getPoints()
  }
}
</script>

<style scoped>
.redeem-poin {
  margin-top: 50px;
}

.redeem-poin h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.redeem-poin h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
}

.gallery_product {
  margin-top: 30px;
  width: 300px;
}

.redeem-poin .gallery_product .centered img {
  vertical-align: middle;
  width: 370px;
  height: 250px;
}

.redeem-poin .gallery_product .centered {
  position: relative;
  margin: 0 auto;
}

.redeem-poin .gallery_product .centered .content {
  position: absolute; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  background-color: #FFEB3B;
  color: #f1f1f1; /* Grey text */
  width: 370px; /* Full width */
  padding-bottom: 10px;
  opacity: 0.8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.redeem-poin .gallery_product .centered .content h4 {
  text-align: left;
  margin: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.redeem-poin .gallery_product .centered .content p {
  margin: 15px;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #1E0E62;
}
</style>