var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"100%","max-width":"60vmax"},model:{value:(_vm.state.openstatus),callback:function ($$v) {_vm.$set(_vm.state, "openstatus", $$v)},expression:"state.openstatus"}},[_c('v-card',{staticClass:"text-center"},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ma-2 error mx-2",attrs:{"x-small":"","fab":""},on:{"click":function($event){_vm.state.openstatus = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('h2',{staticClass:"mb-4"},[_vm._v("Status Pengajuan "+_vm._s(_vm.state.jenisPengajuan))]),_c('v-divider'),[(_vm.state.jenisPengajuan === 'NST')?_c('div',[_c('v-stepper',{staticClass:"hidden-lg-and-up",attrs:{"vertical":""},model:{value:(_vm.e6),callback:function ($$v) {_vm.e6=$$v},expression:"e6"}},[_c('v-stepper-step',{attrs:{"rules":[
            () =>
              (_vm.state.statusPeminjaman === 'RJT' ||
                _vm.state.statusPeminjaman === 'CNCL') &&
              _vm.state.historyApproval.length === 0
                ? false
                : true,
          ],"complete":(_vm.state.statusPeminjaman === 'RJT' ||
              _vm.state.statusPeminjaman === 'CNCL') &&
            _vm.state.historyApproval.length === 0
              ? false
              : true,"step":"1"}},[_vm._v(" Pemohon ")]),(_vm.state.steppers.length > 2)?_c('v-stepper-step',{attrs:{"step":"2","rules":[() => _vm.checkStep(_vm.state.steppers[2].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[2].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[2].jobtitle)+" ")]):_vm._e(),(_vm.state.steppers.length > 2)?_c('v-stepper-content',{class:_vm.checkComplete(_vm.state.steppers[2].jobtitle)
              ? 'brd-green'
              : 'brd-grey',attrs:{"step":_vm.checkNote(_vm.state.steppers[2].jobtitle)}},[_c('v-card',{staticClass:"mb-12 text-left pa-2",attrs:{"color":"#dddddd6e","height":"200px"}},[_vm._v(" "+_vm._s(_vm.state.historyApproval[2] ? _vm.state.historyApproval[2].Notes : ""))])],1):_vm._e()],1),_c('v-stepper',{staticClass:"hidden-sm-and-down",attrs:{"vertical":"","alt-labels":""}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","rules":[
              () =>
                (_vm.state.statusPeminjaman === 'RJT' ||
                  _vm.state.statusPeminjaman === 'CNCL') &&
                _vm.state.historyApproval.length === 0
                  ? false
                  : true,
            ],"complete":(_vm.state.statusPeminjaman === 'RJT' ||
                _vm.state.statusPeminjaman === 'CNCL') &&
              _vm.state.historyApproval.length === 0
                ? false
                : true}},[_vm._v(" Pemohon ")]),_c('v-divider',{attrs:{"color":(_vm.state.statusPeminjaman === 'RJT' ||
                _vm.state.statusPeminjaman === 'CNCL') &&
              _vm.state.historyApproval.length === 0
                ? ''
                : 'primary'}}),(_vm.state.steppers.length > 2)?_c('v-stepper-step',{attrs:{"step":"2","rules":[() => _vm.checkStep(_vm.state.steppers[2].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[2].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[2].jobtitle)+" ")]):_vm._e()],1)],1)],1):_c('div',[_c('v-stepper',{staticClass:"hidden-lg-and-up",attrs:{"vertical":""},model:{value:(_vm.e6),callback:function ($$v) {_vm.e6=$$v},expression:"e6"}},[_c('v-stepper-step',{attrs:{"rules":[
              () =>
                (_vm.state.statusPeminjaman === 'RJT' ||
                  _vm.state.statusPeminjaman === 'CNCL') &&
                _vm.state.historyApproval.length === 0
                  ? false
                  : true,
            ],"complete":(_vm.state.statusPeminjaman === 'RJT' ||
                _vm.state.statusPeminjaman === 'CNCL') &&
              _vm.state.historyApproval.length === 0
                ? false
                : true,"step":"1"}},[_vm._v(" Pemohon ")]),_c('v-stepper-content',{staticClass:"brd-green",attrs:{"step":"2"}},[_c('v-card',{staticClass:"mb-12",attrs:{"color":"#dddddd6e","height":"200px"}})],1),(_vm.state.steppers.length > 0)?_c('v-stepper-step',{attrs:{"step":"2","rules":[() => _vm.checkStep(_vm.state.steppers[0].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[0].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[0].jobtitle)+" ")]):_vm._e(),(_vm.state.steppers.length > 0)?_c('v-stepper-content',{class:_vm.checkComplete(_vm.state.steppers[0].jobtitle)
                ? 'brd-green'
                : 'brd-grey',attrs:{"step":_vm.checkNote(_vm.state.steppers[0].jobtitle)}},[_c('v-card',{staticClass:"mb-12 text-left pa-2",attrs:{"color":"#dddddd6e","height":"200px"}},[_vm._v(_vm._s(_vm.state.historyApproval[0] ? _vm.state.historyApproval[0].Notes : ""))])],1):_vm._e(),(_vm.state.steppers.length > 1)?_c('v-stepper-step',{attrs:{"step":"3","rules":[() => _vm.checkStep(_vm.state.steppers[1].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[1].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[1].jobtitle)+" ")]):_vm._e(),(_vm.state.steppers.length > 1)?_c('v-stepper-content',{class:_vm.checkComplete(_vm.state.steppers[1].jobtitle)
                ? 'brd-green'
                : 'brd-grey',attrs:{"step":_vm.checkNote(_vm.state.steppers[1].jobtitle)}},[_c('v-card',{staticClass:"mb-12 text-left pa-2",attrs:{"color":"#dddddd6e","height":"200px"}},[_vm._v(_vm._s(_vm.state.historyApproval[1] ? _vm.state.historyApproval[1].Notes : ""))])],1):_vm._e(),(_vm.state.steppers.length > 2)?_c('v-stepper-step',{attrs:{"step":"4","rules":[() => _vm.checkStep(_vm.state.steppers[2].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[2].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[2].jobtitle)+" ")]):_vm._e(),(_vm.state.steppers.length > 2)?_c('v-stepper-content',{class:_vm.checkComplete(_vm.state.steppers[2].jobtitle)
                ? 'brd-green'
                : 'brd-grey',attrs:{"step":_vm.checkNote(_vm.state.steppers[2].jobtitle)}},[_c('v-card',{staticClass:"mb-12 text-left pa-2",attrs:{"color":"#dddddd6e","height":"200px"}},[_vm._v(" "+_vm._s(_vm.state.historyApproval[2] ? _vm.state.historyApproval[2].Notes : ""))])],1):_vm._e()],1),_c('v-stepper',{staticClass:"hidden-sm-and-down",attrs:{"vertical":"","alt-labels":""}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","rules":[
                () =>
                  (_vm.state.statusPeminjaman === 'RJT' ||
                    _vm.state.statusPeminjaman === 'CNCL') &&
                  _vm.state.historyApproval.length === 0
                    ? false
                    : true,
              ],"complete":(_vm.state.statusPeminjaman === 'RJT' ||
                  _vm.state.statusPeminjaman === 'CNCL') &&
                _vm.state.historyApproval.length === 0
                  ? false
                  : true}},[_vm._v(" Pemohon ")]),_c('v-divider',{attrs:{"color":(_vm.state.statusPeminjaman === 'RJT' ||
                  _vm.state.statusPeminjaman === 'CNCL') &&
                _vm.state.historyApproval.length === 0
                  ? ''
                  : 'primary'}}),(_vm.state.steppers.length > 0)?_c('v-stepper-step',{attrs:{"step":"2","rules":[() => _vm.checkStep(_vm.state.steppers[0].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[0].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[0].jobtitle)+" ")]):_vm._e(),(_vm.state.steppers.length > 0)?_c('v-divider',{attrs:{"color":_vm.checkComplete(_vm.state.steppers[0].jobtitle) ? 'primary' : ''}}):_vm._e(),(_vm.state.steppers.length > 1)?_c('v-stepper-step',{attrs:{"step":"3","rules":[() => _vm.checkStep(_vm.state.steppers[1].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[1].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[1].jobtitle)+" ")]):_vm._e(),(_vm.state.steppers.length > 1)?_c('v-divider',{attrs:{"color":_vm.checkComplete(_vm.state.steppers[1].jobtitle) ? 'primary' : ''}}):_vm._e(),(_vm.state.steppers.length > 2)?_c('v-stepper-step',{attrs:{"step":"4","rules":[() => _vm.checkStep(_vm.state.steppers[2].jobtitle)],"complete":_vm.checkComplete(_vm.state.steppers[2].jobtitle)}},[_vm._v(" "+_vm._s(_vm.state.steppers[2].jobtitle)+" ")]):_vm._e()],1)],1)],1)],(
        _vm.state.statusPeminjaman === 'RJT' ||
        (_vm.state.statusPeminjaman === 'CNCL' &&
          _vm.state.historyApproval.length > 0)
      )?_c('h2',{staticClass:"hidden-sm-and-down mb-4 mt-10"},[_vm._v(" Alasan Reject/Cancel ")]):_vm._e(),(_vm.NoteReject !== '0' || _vm.NoteReject !== 0)?_c('p',{staticClass:"hidden-sm-and-down text-left pa-4"},[_vm._v(" "+_vm._s(_vm.NoteReject)+" ")]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }