<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="otp bg-header">
      <div class="div-center">
        <div class="content text-center">
          <h3 class="text-center m-4">Masukkan kode OTP</h3>
          <hr />
          <h6 class="text-center">
            Masukkan kode yang terkirim ke nomor telepon Anda
          </h6>
          <form class="digit-group" @submit.prevent="handleVerify($event)">
            <input
              type="text"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              @keypress="isNumber($event)"
              v-model="otp.code1"
              v-on:keyup="$event.target.nextElementSibling.focus()"
              placeholder="0"
              maxlength="1"
              required
            />
            <input
              type="text"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              @keypress="isNumber($event)"
              v-model="otp.code2"
              v-on:keyup="$event.target.nextElementSibling.focus()"
              placeholder="0"
              maxlength="1"
              required
            />
            <input
              type="text"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              @keypress="isNumber($event)"
              v-model="otp.code3"
              v-on:keyup="$event.target.nextElementSibling.focus()"
              placeholder="0"
              maxlength="1"
              required
            />
            <input
              type="text"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              @keypress="isNumber($event)"
              v-model="otp.code4"
              v-on:keyup="$event.target.nextElementSibling.focus()"
              placeholder="0"
              maxlength="1"
              required
            />
            <input
              type="text"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              @keypress="isNumber($event)"
              v-model="otp.code5"
              v-on:keyup="$event.target.nextElementSibling.focus()"
              placeholder="0"
              maxlength="1"
              required
            />
            <input
              type="text"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              @keypress="isNumber($event)"
              v-model="otp.code6"
              v-on:keyup="$event.target.nextElementSibling.focus()"
              placeholder="0"
              maxlength="1"
              required
            />
            <div class="mt-5">
              <a
                href="#"
                v-on:click.prevent="handleResendOTP($event)"
                style="color: #1e0e62"
                >Kirim ulang kode OTP</a
              >
            </div>
            <div class="container mt-5">
              <button class="btn loan-btn-primary btn-block" type="submit">
                Verifikasi
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import OtpService from "@/services/otp.service";
import router from "../router";

export default {
  name: "Otp",
  // components: {NavBar},
  data() {
    return {
      countDown: "",
      otp: {
        code1: "",
        code2: "",
        code3: "",
        code4: "",
        code5: "",
        code6: "",
      },
      resend: false,
      intervalToken: "",
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.auth.status.loggedIn;
    },
    nik() {
      return this.$store.state.otp.data.nik;
    },
  },
  created() {
    if (this.isLogin) {
      this.$router.push("/home");
    }
  },
  methods: {
    handleResendOTP(event) {
      event.preventDefault();
      let loading = this.$loading.show();
      OtpService.getOTP(this.nik).then(() => {
        loading.hide();
        this.otp = {
          code1: "",
          code2: "",
          code3: "",
          code4: "",
          code5: "",
          code6: "",
        };
      });
    },
    handleVerify(event) {
      event.preventDefault();
      let loading = this.$loading.show();
      let params = {
        nik: this.nik,
        otp_code:
          this.otp.code1 +
          this.otp.code2 +
          this.otp.code3 +
          this.otp.code4 +
          this.otp.code5 +
          this.otp.code6,
      };
      OtpService.postVerify(params).then((response) => {
        loading.hide();
        if (response.code === 200) {
          router.push("/reset-password");
        } else {
          this.$swal.fire("Alert!", response.message, "error");
        }
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.digit-group input {
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 98px;
  text-align: center;
  font-feature-settings: "liga" off;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border: 1px solid #1e0e62;
  border-radius: 5px;
  box-sizing: border-box;
  color: #1e0e62;
  margin: 0 2px;
}

.digit-group input:hover {
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 98px;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border: 1px solid #1e0e62;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  color: #1e0e62;
  margin: 0 2px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  .digit-group input {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 98px;
    width: 36px;
    height: 60px;
    background-color: #ffffff;
    border: 1px solid #1e0e62;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    color: #1e0e62;
    margin: 0 2px;
  }

  .digit-group input:hover {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 98px;
    width: 36px;
    height: 60px;
    background-color: #ffffff;
    border: 1px solid #061b43;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    color: #1e0e62;
    margin: 0 2px;
  }
}
</style>
