<template>
  <div class="loan">
    <NavBar/>
    <div class="account">
      <div class="container">
        <h1>Edit Account</h1>
        <div class="mt-5 mb-4">
          <div class="account-image text-center">
            <div class="avatar image-upload">
              <label for="file-input1"><img
                  v-if="!user.image"
                  src="~@/assets/images/Avatar.png"
                  class="rounded-circle"
                  width="150"
                  height="150"
                  style="background-color: #444444;cursor: pointer;"
              />
                <img
                    v-if="user.image"
                    :src="user.image"
                    class="rounded-circle"
                    width="150"
                    height="150"
                    style="background-color: #444444;cursor: pointer;"
                />
              </label>
              <input
                  id="file-input1"
                  @change="onFileChange"
                  accept="image/*"
                  type="file"
              />
            </div>
            <h2>{{ user.name }}</h2>
            <h6>{{ user.NIK }}</h6>
          </div>
          <div class="container account-detail">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/JumlahTenor.png" width="20" alt="date" class="mr-2">Tanggal lahir</span>
                  <input type="text" class="form-control" readonly>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/gender.png" width="20" alt="date" class="mr-2">Gender</span>
                  <input type="text" class="form-control" readonly>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/Email.png" width="20" alt="date" class="mr-2">Email</span>
                  <input type="email" class="form-control" readonly>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/phone.png" width="20" alt="date"
                             class="mr-2">Nomor telepon</span>
                  <input type="text" class="form-control" required>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/pin.png" width="20" alt="date" class="mr-2">Alamat</span>
                  <textarea class="form-control" rows="5" required></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="container text-center mt-5">
            <button class="btn loan-btn-primary mr-1 w-25" @click="handleChange">Change</button>
            <button class="btn loan-btn-secondary ml-1 w-25" @click="handleCancel">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import NavBar from "@/components/navigation/NavBar";
import Footer from "@/components/Footer";
import UserService from "@/services/user.service";
import router from "../../router";

export default {
  name: "EditAccount",
  components: {Footer, NavBar},
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      rePassword: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    }
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.user.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleCancel(){
      router.back();
    },
    handleChange() {
      if (this.newPassword === this.rePassword) {
        let params = {
          "id": this.user.id,
          "old_password": this.oldPassword,
          "new_password": this.newPassword
        }
        let loading = this.$loading.show();
        UserService.postChangePassword(params).then(response => {
          if (response.code === 200) {
            loading.hide();
            this.hideModal();
            this.$swal.fire({
              icon: 'success',
              text: 'Your password has been change \n Please login again',
              confirmButtonText: `Logout`
            }).then((result) => {
              if (result.isConfirmed) {
                this.$store.dispatch('auth/logout');
              }
            })
          } else {
            loading.hide();
            this.$swal.fire(
                'Alert!',
                response.message,
                'error'
            )
          }
        })
      } else {
        this.$swal.fire(
            'Alert !',
            'Password dan repasswod tidak sama !',
            'warning'
        )
      }
    }
  }
}
</script>

<style scoped>
.account {
  margin-top: 100px;
}

.account .image-upload > input {
  display: none;
}
.account-image .avatar img {
  background-color: #1E0E62;
  width: 150px;
  border-radius: 360px;
}

.account-image h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.account-image h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}

.account-detail {
  padding: 50px;
}

.account-detail .account-field {
  margin: 20px;
}

.account-detail .account-field span {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.account-detail .account-field input,textarea {
  margin-top: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #2F3035;
  mix-blend-mode: normal;
}
</style>