<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="application-form-approved">
      <div class="container">
        <div class="mt-5 mb-5">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
              <h2>Pinjaman berhasil cair!</h2>
              <h6>Pinjaman Anda sejumlah Rp {{currency(approved.LoanAmount)}} telah disetujui dan akan dicairkan</h6>
              <span>Uang Pinjaman akan sampai ke rekening Anda paling lambat dalam waktu 48 jam</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <img src="~@/assets/images/PinjamanCairNew.png" width="400" alt="" class="ml-2">
            </div>
          </div>
          <div class="row loan-detail">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="loan-approved-item">
                <img src="~@/assets/images/icons/Bank1.png" alt="">
                <h5 class="mt-4">Rekening :{{approved.payrollaccountno}}</h5>
                <h5 >Atas nama {{approved.payrollname}}</h5>
                <h6 class="mt-2">Nomor rekening di atas akan ditransfer pinjamannya</h6>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="loan-approved-item">
                <img src="~@/assets/images/icons/JumlahTenor.png" width="80" alt="">
                <h5 class="mt-4">Rp {{currency(approved.InstallmentAmount)}}/{{approved.Tenor}} BULAN</h5>
                <h6 class="mt-2">Cicilan pelunasan akan dibayarkan selama {{approved.Tenor}} kali sejumlah Rp {{currency(approved.InstallmentAmount)}}</h6>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="loan-approved-item">
                <img src="~@/assets/images/icons/jumlahCicilan.png" width="80" alt="">
                <h5 class="mt-4">Rp {{currency(approved.TotalPencairan)}}</h5>
                <h6 class="mt-2">Jumlah pinjaman yang akan Anda terima sejumlah Rp {{currency(approved.TotalPencairan)}} dengan detail Potongan antara lain :  <br>1. Admin Fee Rp {{currency(approved.FeeAdmin)}}, <br>2. Sisa Pokok Rp {{currency(approved.sisapokok)}}, <br>3. Sisa Bunga Rp {{currency(approved.sisabunga)}}, <br>4. PTA Rp {{currency(approved.pta)}}</h6>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="loan-approved-item">
                <img src="~@/assets/images/icons/JumlahTenor.png" width="80" alt="">
                <h5 class="mt-4">Rp {{currency(approved.TotalPencairan)}}</h5>
                <h6 class="mt-2">Tanggal Funding :  {{approved.TglPencairan}}<br> Tanggal Potongan Pertama : {{approved.TglPotongan}}</h6>
              </div>
            </div>
          </div>
          <div class="container mt-5 mb-5 text-center">
            <router-link class="btn loan-btn-primary w-25 mt-5" to="/application-form">Laman Pinjaman</router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import Utils from '@/helper';
import router from "../router";

export default {
  name: "LoanApplicationFormapproved",
  components: {Footer},
  methods: {
    currency(nominal) {
      return Utils.currencyRp(nominal);
    }
  },
  computed: {
    approved() {
      return this.$store.state.notification.approve;
    }
  },
  created() {
    if(this.approved.length<1)router.push('/notification');
  }
}
</script>

<style scoped>
.application-form-approved {
  margin-top: 100px;
}

.application-form-approved h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: -1px;
  color: #1E0E62;
}

.application-form-approved h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}

.application-form-approved span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: red;
  mix-blend-mode: normal;
}

.loan-detail {
  text-align: center;
  padding-top: 150px;
}

.loan-detail .loan-approved-item h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.loan-detail .loan-approved-item h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}
</style>