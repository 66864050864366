import Api from '@/api'
import Host from '@/api/host'

class PointService {
    getPoint() {
        return Api.doGet(Host.LOAN_V1 + '/Poin');
    }

    getHistoryPoint(id) {
        return Api.doGet(Host.LOAN_V1 + '/Poin/History/' + id);
    }

    postRedeemPoint(params) {
        return Api.doPost(Host.LOAN_V1 + '/Poin/Redeem', params);
    }
}

export default new PointService();