<template>
  <div v-if="histories.length > 0" class="loan-history">
    <div class="container mt-5">
      <h3 class="text-white">Riwayat Pinjaman</h3>
      <!-- {{ JSON.stringify(histories) }} -->
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-for="item in histories" :key="item.id">
          <div class="loan-history-item">
            <div class="row">
              <div class="col-3">
                <div class="row">
                  <img :src="item.ImagesPath" alt="item.id">

                  <div v-if="item.Status == 'RJT' ">
                    <v-btn class="primary mt-3" rounded @click="showModal()">Detail</v-btn>
                    <!-- <button class="btn loan-btn-primary mr-3 mt-5" style="border-radius: 6px" @click="showModal()">
                      Detail
                    </button> -->
                  </div>
                  <div v-else-if="item.Status == 'FUN' ">
                    <router-link :to="{ name: 'ReportAmortisasi', params: { AgreementNo: item.AgreementNo}}">
                      <v-btn rounded class="yellow mt-4">Amortisasi</v-btn>
                      <!-- <button class="btn loan-btn-primary mr-3 mt-5" style="border-radius: 6px">
                        Amortisasi
                      </button> -->
                    </router-link>
                  </div>
                  <div v-else-if="item.Status == 'NEW' && !item.HC_Checklist && !item.HC_Recommendations && item.JenisPengajuan !== 'NST'">
                    <v-btn rounded class="error mt-4" @click="cancelPengajuan({id:item.id})"> Cancel</v-btn>
                  </div>

                  <div>
                    <v-btn rounded class="warning mt-4" @click="modalStatusPengjuan({ ApprovalNo: item.ApprovalNo, Open: true, Status: item.Status, JenisPengajuan: item.JenisPengajuan})">Status</v-btn>
                  </div>
                </div>
              </div>
              <div class="col-9">
                <div class="row">
                  <div class="col-8 text-left">
                    <h6>Tujuan</h6>
                    <h5 class="text-white">{{ item.FinancePurpose }}</h5>
                  </div> 
                  <div class="col-4 text-right">
                    <h6 v-if="item.Status == 'NEW'" class="text-warning">Baru</h6>
                    <h6 v-else-if="item.Status == 'RJT'" class="text-warning">Reject</h6>
                    <h6 v-else-if="item.Status == 'APV'" class="text-warning">Approval</h6>
                    <h6 v-else-if="item.Status == 'FUN'" class="text-warning">Funding</h6>
                    <h6 v-else-if="item.Status == 'PAID'" class="text-warning">Lunas</h6>
                    <h6 v-else>Cancel</h6>
                    
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-left">
                    <h6>Jumlah Pinjaman</h6>
                    <h5 class="text-white">Rp {{ currency(item.LoanAmount) }}</h5>
                  </div>
                </div>
                <h6>Tenor</h6>
                <div class="row">
                  <div class="col-8 text-left">
                    <h5 class="text-white">{{ item.Tenor }} Bulan</h5>
                  </div>
                  <div class="col-4 text-right">
                    <h6  class="text-warning">{{ item.SubmitDate | moment('DD-MM-YYYY') }}</h6>                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal centered ref="my-modal" hide-footer hide-header>
      <div class="d-block text-center">
        <h3>Pengajuan NST</h3>
        <form class="loan-history-popup" role="form" v-for="item in histories"
              :key="item.id">

          <div class="row">
            <div class="col-12 text-center">
              <h5>Rp {{ currency(item.LoanAmount) }}</h5>
              <div class="dv-divider-solid"></div>
              <h6>Jumlah bunga {{ item.EffectiveRate }}% dengan tenor {{ item.Tenor }} Bulan </h6>
            </div>
          </div><br>
          <div class="row">
            <div class="col-12 text-center">
              <h6 style="font-size:10px">Notes HCComben :  {{ item.Notes_HCRecommendation }}</h6>
            </div>
          </div>
        </form>
        <div class="row text-center">
          <div class="col-12 text-center">
            <button class="btn loan-btn-orange w-50" @click="submit('0')">
              Batalkan Pinjaman
            </button>
          </div>
          <div class="col-12 text-center mt-2">
            <button class="btn loan-btn-primary w-50" @click="submit('1')">
              Ajukan Pinjaman
            </button>
          </div>
        </div>

      </div>
    </b-modal>
    <StatusPinjaman/>
  </div>

</template>

<script>
import Utils from '@/helper';
import router from "../../router";
import LoanService from "@/services/loan.service";
import StatusPinjaman from '../modal/StatusPinjaman.vue';
import { mapActions } from 'vuex';

export default {
  name: "LoanHistory",
  components: {
    StatusPinjaman
  },
  data() {
    return {
      paramsss: {
        id: "",
        NIK: "",
        approval:""
      },
    }
  },
  props: {
    nik: String
  },
  computed: {
    histories() {
    //  console.log(this.$store.state.loan.histories)
      return this.$store.state.loan.histories;
    }
  },
  methods: {
    ...mapActions('loan',[
      'modalStatusPengjuan', 'cancelPengajuan'
    ]),
    currency(nominal) {
      return Utils.currencyRp(nominal);
    },

    showModal() {
      this.$refs['my-modal'].show()
    },

    hideModal() {
      this.$refs['my-modal'].hide()
    },    

    submit(param) {
      const datass = this.$store.state.loan.histories[0];
      let params = {
        id: datass.id,
        NIK: datass.NIK,
        approval: param,
      };
      let loading = this.$loading.show();
      LoanService.postPengajuanPinjamanNST(params).then(response => {
        if (response.code === 200) {
          if (param == '1') {
            router.go('/application-form-process')
            window.location = "/application-form-process";
          } else {
            router.go('/application-form-cancel')
            window.location = "/application-form-cancel";
          }
          
        } else {
          loading.hide();
          this.$swal.fire(
              'Alert!',
              response.message,
              'error'
          )
        }
      })
    },

    // submit(param) {
    //   const datass = this.$store.state.loan.histories[0];
    //   let params = {
    //     id: datass.id,
    //     NIK: datass.NIK,
    //     approval: param,
    //   };
    //   console.log(params);
    //   LoanService.postPengajuanPinjamanNST(params)
    //       .then(() => {
    //        // console.log(this.$store.state.loan.histories)
    //       //  console.log("Berhasil Di tambahkan", response,);
    //         this.$swal.fire({
    //           icon: "success",
    //           title: "Success",
    //           text: "Berhasil Diajukan",
    //         });
    //       })
    //       .catch((error) => {
    //         console.log("Gagal Diajukan", error.res);
    //       });
    // },


  }
}
</script>

<style scoped>
.loan-history {
  /* margin-top: 50px; */
  background-color: #04559F;
  padding-top: 20px;
  padding-bottom: 90px;
  min-height: 500px;
}

.loan-history-popup {
  margin-top: 50px;
  background-color: #FFEB3B;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 100px;
  margin-left: 110px;
  margin-right: 110px;
  margin-bottom: 50px;
}

.dv-divider-solid {
  border-top: 3px solid #1E0E62;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.loan-history-item {
  margin-top: 30px;
  mix-blend-mode: normal;
  border: 2px solid #1E0E62;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px 30px 20px;
  cursor: pointer;
}

.loan-history-item:hover {
  margin-top: 30px;
  mix-blend-mode: normal;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px 30px 20px;
  cursor: pointer;
}

.loan-history-item img {
  width: 90px;
  padding-right: 20px;
}

.loan-history-item h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  color: #1E0E62;
}

.loan-history-item h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1E0E62;
}
</style>
