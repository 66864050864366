import Api from '@/api'
import Host from '@/api/host'

class UserService {
    postChangePassword(param) {
        return Api.doPost(Host.LOAN_V1 + '/change-password', param);
    }

    postChangeProfile(id, param) {
        return Api.doPost(Host.LOAN_V1 + '/ChangeProfile/' + id, param);
    }

    postupdateData(id, param) {
        return Api.doPost(Host.LOAN_V1 + '/updateData/' + id, param);
    }
}

export default new UserService();