<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="reset-password bg-header">
      <div class="div-center">
        <div class="content">
          <h3 class="text-center m-4">Reset Password</h3>
          <hr>
          <h6 class="text-center">Silahkan masukkan kembali password Anda untuk reset password</h6>
          <form class="m-5" role="form" @submit.prevent="handleSubmit($event)">
            <div class="form-group">
              <input type="password" class="form-control loan-input-control" id="Password baru"
                     placeholder="Password baru" v-model="newPassword" required>
            </div>
            <div class="form-group">
              <input type="password" class="form-control loan-input-control" id="repassword"
                     placeholder="Confirm password baru" v-model="rePassword" required>
            </div>
            <div class="form-group m-5">
              <input type="submit" class="btn loan-btn-primary btn-block" value="Masukan">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import router from "../router";
import OtpService from "@/services/otp.service";

export default {
  name: "ResetPassword",
  // components: {NavBar},
  data() {
    return {
      newPassword: '',
      rePassword: ''
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.auth.status.loggedIn;
    },
    data() {
      return this.$store.state.otp.data;
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      let loading = this.$loading.show();
      if (this.newPassword === this.rePassword) {
        let params = {
          nik: this.data.nik,
          new_password: this.newPassword,
          id: this.data.id
        }
        OtpService.postResetPassowrd(params).then((response) => {
          if (response.code === 200) {
            loading.hide()
            router.push('/login')
          } else {
            loading.hide()
            this.$swal.fire(
                'Alert!',
                response.message,
                'error'
            )
          }
        });
      } else {
        loading.hide()
        this.$swal.fire(
            'Alert!',
            'Password baru tidak sama dengan repassword',
            'error'
        )
      }
    },
  },
  created() {
    if (this.isLogin) {
      this.$router.push("/home");
    }
  },
}
</script>

<style scoped>

</style>