import NotificationService from "@/services/notification.service";

const notification = {
    namespaced: true,
    state: {
        data: [],
        approve:[]
    },
    actions: {
        getAll({commit},id) {
            NotificationService.getAll(id).then((response) => {
                commit('SET_RECORDS', response.data);
            })
        },
        approve({commit},data) {
            commit('SET_APPROVED',data);
        }
    },
    mutations: {
        SET_RECORDS(state, data) {
            state.data = data;
        },
        SET_APPROVED(state, data) {
            state.approve = data;
        }
    }
};

export default notification;