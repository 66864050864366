<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="repayment">
      <div class="container">
        <Back/>
        <div class="container mt-4">
          <h1 class="text-center">Pelunasan</h1>
          <div class="container text-center">
            <div class="repayment-header">
              <h6>Status Pengajuan Pelunasan</h6>
              <h5>Sedang dalam proses</h5>
              <img src="~@/assets/images/icons/clock.png" class="clock" alt="clock">
              <hr>
              <h6>Jumlah yang harus dibayar</h6>
              <h5>Rp {{ currency(statusLoan.TotalPelunasan) }}</h5>
              <p>(OSP Rp {{ currency(statusLoan.OSP) }} + Bunga Rp {{ currency(statusLoan.Interest) }})</p>
            </div>
            <div class="repayment-history">
              <h5 class="text-left m-3">Riwayat Pembayaran</h5>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">Tenor</th>
                  <th scope="col">Tanggal Jatuh <Tempo></Tempo></th>
                  <th scope="col">Angsuran</th>
                  <th scope="col">Tanggal Pembayaran</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="loan in historyPelunasan" :key="loan.id">
                  <td v-if="loan.PaidAmount != null" style="color: green">{{ loan.InsSeqNo }}/{{ loan.Tenor }}</td>
                  <td v-else>{{ loan.InsSeqNo }}/{{ loan.Tenor }}</td>
                  <td v-if="loan.PaidAmount != null" style="color: green">Lunas</td>
                  <td v-else>{{ loan.InstallmentDueDate | moment('DD MMM YYYY') }}</td>
                  <td v-if="loan.PaidAmount != null" style="color: green">Rp. {{ currency(loan.InstallmentAmount) }}</td>
                  <td v-else>Rp. {{ currency(loan.InstallmentAmount) }}</td>
                  <td v-if="loan.PaidAmount != null" style="color: green">{{ loan.InstallmentDueDate | moment('DD MMM YYYY') }}</td>
                  <td v-else>-</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="container p-5">
              <button v-if="statusLoan.statuspelunasan == NULL" class="btn loan-btn-primary w-25" data-toggle="modal" data-target="#pelunasan">Lakukan
                pelunasan
              </button>
              <button v-else-if="statusLoan.statuspelunasan == 'PT1'" class="btn loan-btn-primary w-25" disabled>Lakukan
                pelunasan
              </button>

              <div class="container p-6" v-else-if="statusLoan.statuspelunasan == 'PT2'" >
                <button class="btn loan-btn-orange w-25" @click="handleCek">Pembayaran
                  pelunasan
                </button><br><br>
                
                <label>
                  <input type="file" id="file-input1" 
                        @change="onFileChange1"
                        accept="image/*"/>
                  <img src="~@/assets/images/file.png" style="width: 46px"> Upload Bukti Pembayaran
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    
    <div class="modal fade" id="pelunasan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Info</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="message-text" class="col-form-label">Rencana Pelunasan : </label>
              <input type="date" class="form-control" v-model="tgl_pelunasan">
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="handlePelunasan">submit</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import Back from "../components/Back";
import Utils from '@/helper';
import LoanService from '../services/loan.service';
import router from "../router";

export default {
  name: "Repayment",
  components: {Back, Footer},
  data(){
    return{
      image1: null,
      tgl_pelunasan: ""
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    historyPelunasan() {
      return this.$store.state.loan.historyPelunasan;
    },
    statusLoan() {
      return this.$store.state.loan.status;
    }
  },
  methods: {
    onFileChange1(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage1(files[0]);
      this.handleSubmit();
    },
    createImage1(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image1 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleSubmit() {
        let params = {
          BuktiPelunasan: this.image1,
        }
        let loading = this.$loading.show();
        LoanService.UploadBuktiPelunasan(this.statusLoan.id_pretermination, params).then(response => {
          if (response.code === 200) {
            this.$swal.fire(
                'Success!',
                'Upload Bukti Pembayaran Pelunasan Berhasil Dilakukan',
                'success'
            )
            loading.hide();
            location.reload();
          } else {
            loading.hide();
            this.$swal.fire(
                'Error!',
                response.message,
                'error'
            )
          }
        })
    },
    currency(nominal) {
      return Utils.currencyRp(nominal);
    },
    handleCek(){
      this.$router.push("/self-payment");
    },
    handlePelunasan() {
      this.$swal.fire({
        icon: 'question',
        text: 'Apakah yakin akan melakukan pelunasan ?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          let loading = this.$loading.show();
          let params = {
            id: this.statusLoan.id,
            NIK: this.user.NIK,
            TglPengajuan: this.tgl_pelunasan,
            PaidAmount: this.statusLoan.TotalPelunasan
          }
          LoanService.postPelunasan(params).then(
              (response) => {
                loading.hide();
                if (response.code === 200) {
                  // this.$router.push("/self-payment");
                  this.$swal.fire(
                      'Success!',
                      'Pengajuan Pelunasan anda berhasil diajukan',
                      'success'
                  )
                  router.go();
                }
              },
              (error) => {
                loading.hide();
                this.$swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                );
              }
          );
        }
      })
    }
  },
  mounted() {
    this.$store.dispatch('loan/getHistoryPelunasan', this.user.NIK);
    this.$store.dispatch('loan/getStatusPelunasan', this.user.NIK);
  }
}
</script>

<style scoped>
.repayment {
  margin-top: 100px;
}

.repayment input[type="file"] {
  display: none;
}

.repayment h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.repayment-header {
  margin: 50px;
  padding: 50px;
  background: #FFFFFF;
  border: 1px solid #D4DFEF;
  border-radius: 10px;
}

.repayment-header h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1C1939;
}

.repayment-header h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #F57F17;
}

.repayment-header p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: #1C1939;
}

.repayment-history {
  margin: 50px;
  padding: 50px;
  background: #FFFFFF;
  border: 1px solid #D4DFEF;
  border-radius: 10px;
}

.repayment-history h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1C1939;
}

.repayment-history table tr td {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1C1939;
}
</style>