<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="notification">
      <div class="container">
        <div class="mt-5 mb-5">
          <div class="text-left">
            <h1>Notifikasi</h1>
          </div>
          <div class="notification-detail">
            <div class="list-group mb-1" v-for="item in notifications" :key="item.id">
              <div class="list-group-item list-group-item-action flex-column align-items-start"
                   style="border-radius: 10px" @click="detail(item)">
                <div class="d-flex w-100 justify-content-between">
                  <h5>{{ item.LogHeader }}</h5>
                  <!--                  <span class="badge" style="cursor: pointer;"><b-icon icon="x" font-scale="3"></b-icon></span>-->
                </div>
                <p class="mb-1">{{ item.LogDesc }}</p>
                <div class="mt-1">
                  <small class="text-muted">{{ item.LogDate }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import router from "../router";

export default {
  name: "Notification",
  // components: {NavBar},
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    notifications() {
      return this.$store.state.notification.data;
    }
  },
  methods: {
    detail(item) {
      if (item.LogHeader === 'APV') {
        this.$store.dispatch('notification/approve',item);
        router.push('/application-form-approved')
      } else if(item.LogHeader ==='FUN'){
        router.push('/application-form-funding')
      } else if(item.LogHeader ==='RJT'){
        router.push('/application-form-rejected')
      } else if(item.LogHeader ==='CNCL'){
        router.push('/application-form-rejected')
      }else{
        this.$store.dispatch('loan/setMyLoan',item);
        router.push('/application-form-process')
      }
    }
  },
  mounted() {
    this.$store.dispatch('notification/getAll', this.user.id);
  }
}
</script>

<style scoped>
.notification {
  margin-top: 100px;
}

.notification h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #151515;
}

.notification-detail {
  margin-top: 30px;
}

.notification-detail h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #797979;
}

.notification-detail p {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #232526;
}
</style>