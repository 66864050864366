<template>
  <div class="loan">
    <!-- <nav-bar/> -->
    <div class="faq">
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-12 col-md-12 text-center">
            <h3 class="mt-5">Pertanyaan yang sering diajukan (FAQ)</h3>
          </div>
        </div>
        <div class="container">
          <div class="bank mt-5">
            <div class="panel-group" id="accordion">
              <div class="panel panel-default" v-for="faq in faq" :key="faq.id">
                <div class="panel-heading">
                  <div class="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" v-bind:href="'#collapse'+faq.id"  @click="toggle(faq.Category)"
                       class="text-left">
                      {{ faq.Category }} <span class="text-right"><b-icon icon="chevron-down"
                                                                          font-scale="1"
                                                                          @click="toggle(faq.Category)"></b-icon></span></a>
                  </div>
                </div>
                <div v-bind:id="'collapse'+faq.id" class="panel-collapse collapse in">
                  <div class="panel-body">
                    <span v-html="faqDetail.Answer"></span>
                    <!-- {{ faqDetail.Answer }} -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";

export default {
  name: "faq",
  components: {Footer},
  computed: {
    faq() {
      return this.$store.state.faq.data;
    },
    faqDetail() {
      return this.$store.state.faq.detail;
    }
  },
  methods: {
    toggle(category) {
      let params = {
        Category: category
      }
      let loading = this.$loading.show();
      this.$store.dispatch('faq/getDetail', params).then(()=>{
        loading.hide();
      });
    }
  },
  mounted() {
    this.$store.dispatch('faq/getAll');
  }
}
</script>

<style scoped>
.bank div .panel-title {
  width: 100%;
  display: block;
  padding-top: 10px;
  margin-top: 10px;
  color: #555;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 3px;
  border-bottom: 2px solid #EBEAED;
}

.bank div .panel-title a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;;
  color: rgba(21, 20, 57, 0.4);
}

.panel-title a span {
  display: block;
  color: #555;
  margin-top: -30px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 3px;
  text-decoration: none;
}

.bank .panel-body {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: rgba(21, 20, 57, 0.4);
}
</style>