<template>
  <!-- Navbar -->
  <div class="responsive">
    
    <nav class="navbar navbar-expand-lg bg-nav fixed-top">
      <button class="navbar-toggler  navbar-toggler-right" type="button" data-toggle="collapse"
              data-target="#navbarNav">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Mobile Navbar Responsive -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav col-md-4 ">
          <li class="nav-item">
            
            <router-link v-if="!isLogin" to="/" class="navbar-brand font-weight-bold ml-5">
              <v-img src="~@/assets/images/bfi.png" width="50px"></v-img> Bakti Fajar Idaman
            </router-link>
            <router-link v-if="isLogin" to="/home" class="navbar-brand font-weight-bold ml-5">
              Bakti Fajar Idaman
            </router-link>
          </li>
        </ul>
        <ul v-if="!isLogin" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/about">Tentang Kami</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/organisasi" class="nav-link">
              Struktur Organisasi
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/blog" tabindex="-1" aria-disabled="true">Blog</router-link>
          </li>
          <li class="nav-item ml-2 mr-5">
            <router-link to="/login" class="btn btn-masuk">
              Masuk
            </router-link>
          </li>
        </ul>
        <ul v-if="isLogin" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">
              Home
            </router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="inquiry.Status != 'Sedang dalam approval' && user.email != null && user.mobilephoneno != null" class="nav-link" to="/application-form">Pinjaman</router-link>
            <router-link v-else class="nav-link" to="#" data-toggle="modal" data-target="#exampleModal">Pinjaman</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/poins" class="nav-link">
              Poin
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/blog" tabindex="-1" aria-disabled="true">Blog</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/faq" tabindex="-1" aria-disabled="true">FAQ</router-link>
          </li>
          <li class="nav-item ml-2">
            <router-link to="/notification" class="btn">
              <b-icon icon="bell" rotate="1"></b-icon>
            </router-link>
          </li>
          <li class="nav-item ml-2 mr-4">
            <router-link to="/account" class="btn">
              <b-icon icon="person-circle" rotate="1"></b-icon>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Info</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Anda belum bisa melakukan pengajuan Pinjaman
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Navbar -->
</template>

<script>
import LoanService from '../../services/loan.service';
export default {
  name: "NavBar",
  computed: {
    isLogin() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user.user;
    }
  },
  data(){
    return {
      inquiry: [],
    }
  },
  created() {
    LoanService.getInquiryStatus(this.$store.state.auth.user.user.NIK)
        .then((response) => {
          this.inquiry = response.data;
          console.log('inquiry = ' + JSON.stringify(this.inquiry));
        })
        .catch((error) => {
          console.log("Eror Data Tidak Di Temukan", error.response);
        });
  },
}
</script>

<style scoped>

</style>