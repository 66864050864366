class Utils {
    setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    toJSON(json) {
        if (json !== undefined || json !== null) {
            let record = JSON.parse(json);
            return JSON.parse(record);
        }
    }

    currencyRp(nominal) {
        let number_string = nominal ? nominal.toString() : '0';
        let sisa = number_string.length % 3;
        let rupiah = number_string.substr(0, sisa);
        let ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
            let separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        return rupiah;
    }

    toRupiah (nominal) {
        return nominal.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.')
    }

    resizeImage(e) {
        let img = new Image();
        img.src = e;
        img.id = Math.random().toString(20).substr(2, 6);
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let MAX_WIDTH = 400;
        let MAX_HEIGHT = 350;
        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx2 = canvas.getContext("2d");
        ctx2.drawImage(img, 0, 0, width, height);

        return canvas.toDataURL("image/png");
    }
}

export default new Utils();