<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="application-form-rejected">
      <div class="container">
        <div class="mt-5 mb-5">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
              <h2>Pengajuan Berhasil dicancel</h2>
              <h6>Pengajuan pinjaman telah anda cancel.</h6>
              <span>Harap coba ajukan pinjaman kembali jika diperlukan, terimakasih</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <img src="~@/assets/images/PinjamanDitolakNew.png" width="300" alt="" class="m-5">
            </div>
          </div>
          <div class="container mt-5 mb-5 text-center">
            <router-link class="btn loan-btn-primary w-25 mt-5" to="application-form">Laman Pinjaman</router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";

export default {
  name: "LoanApplicationFormRejected",
  components: {Footer}
}
</script>

<style scoped>
.application-form-rejected {
  margin-top: 100px;
}

.application-form-rejected h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: -1px;
  color: #1E0E62;
}

.application-form-rejected h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}

.application-form-rejected span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: red;
  mix-blend-mode: normal;
}

.loan-detail {
  text-align: center;
  padding-top: 150px;
}

.loan-detail .loan-rejected-item h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.loan-detail .loan-rejected-item h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}
</style>