import Api from '@/api'
import Host from '@/api/host'

class FaqService {
    getAll() {
        return Api.doGet(Host.LOAN_V1 + '/FAQ');
    }

    postDetail(params) {
        return Api.doPost(Host.LOAN_V1 + '/FAQ/Detail',params);
    }
}

export default new FaqService();