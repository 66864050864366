import ProductService from "@/services/product.service";

const product = {
    namespaced: true,
    state: {
        data: []
    },
    actions: {
        getAll({commit}) {
            ProductService.getAll().then((response) => {
                commit('SET_RECORDS', response.data);
            })
        }
    },
    mutations: {
        SET_RECORDS(state, data) {
            state.data = data;
        }
    }
};

export default product;