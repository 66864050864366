<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="container blog-slug">
      <div class="slug-title">
        <span><router-link to="/">Home</router-link> / <router-link
            to="/blog">Blog </router-link> / {{ slugs.TitleName }}</span>
      </div>
      <div class="slug-header">
        <img :src="slugs.ImagesPath" alt="Snow" style="width:100%; height: 400px;">
        <div class="bottom-left">
          <div>Tanggal&nbsp;&nbsp; : {{ slugs.Createdate | moment('DD, MMM, YYYY') }}</div>
          <div>Category : {{ slugs.Category }}</div>
          <div>Penulis&nbsp;&nbsp;&nbsp;&nbsp; : {{ slugs.WritenBy }}</div>
        </div>
        <div class="centered d-none d-md-block d-sm-block">
          <h2>{{ slugs.TitleName }}</h2>
        </div>
      </div>
      <div class="slug-body">
        <div class="row">
          <div class="col-md-3 col-12">
            <div class="slug-tags">
              <h5>Tags</h5>
              <div class="slug-button-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-secondary">{{ slugs.Tags }}</button>
              </div>
            </div>
          </div>
          <div class="col-md-9 col-12">
            <div class="slug-content">
              <div class="content">
                <span v-html="slugs.Blog_Description"></span>
              </div>
              <div class="soc-share mt-5">
                <span class="slug-button-group">Bagikan :
                  <a href="https://facebook.com/sharer/sharer.php?u=@Model.Item.DefaultUrl" target="_blank"
                     type="button" class="btn btn-outline-light"><i class="mr-2"><img
                      src="~@/assets/images/icons/FB.png" alt="sosmes-icons" width="20px"/></i>Facebook</a>
                    <a href="https://twitter.com/intent/tweet/?text=titleToBeReplaced&amp;url=urlToBeReplaced"
                       target="_blank" type="button" class="btn btn-outline-light"><i class="mr-2"><img
                        src="~@/assets/images/icons/Twitter.png" alt="sosmes-icons" width="20px"/></i>Twitter</a>
                    <a type="button" class="btn btn-outline-light"><i class="mr-2"><img
                        src="~@/assets/images/icons/IG.png" alt="sosmes-icons" width="20px"/></i>Instagram</a>
                </span>
              </div>
              <hr/>
              <div class="comment mt-2">
                <h4>Tinggalkan komentar</h4>
                <h6>Sudah memiliki akun?
                  <router-link to="/login"> Masuk</router-link>
                  untuk meninggalkan balasan.
                </h6>
              </div>
              <div class="comment-form mt-3">
                <form role="form" @submit.prevent="submit">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <label for="name">Nama</label>
                        <input type="text" class="form-control" v-model="name" id="name" aria-describedby="emailHelp"
                               placeholder="Nama" required>
                      </div>
                      <div class="col-md-6 col-12">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" class="form-control" v-model="email" id="exampleInputEmail1"
                               aria-describedby="emailHelp"
                               placeholder="email" required>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Komentar</label>
                    <textarea class="form-control" rows="5" placeholder="Komentar" v-model="comment"
                              required></textarea>
                  </div>
                  <div class="form-check mt-4">
                    <input v-model="commit" type="checkbox" class="form-check-input" id="exampleCheck1">
                    <label class="form-check-label" for="exampleCheck1">Simpan nama dan email saya pada peramban ini
                      untuk komentar lain kali.</label>
                  </div>
                  <button type="submit" class="btn loan-btn-secondary mt-4">Send a comment</button>
                </form>
              </div>
              <hr/>
              <h5>Komentar</h5>
              <div class="list-comment mt-3">
                <div v-for="item in comments" :key="item.id" class="list-comment-item">
                  <div class="row">
                    <div class="col-md-1 col-2">
                      <img src="../../assets/images/Photo2.png" alt="Snow"
                           style="width:50px;height:45px;border-radius: 50%;">
                    </div>
                    <div class="col-md-10 col-10 text-left">
                      <h6>Author <span class="btn-tag">{{ item.nama }}</span></h6>
                      <span>{{ item.created_at | moment('DD MMM YYYY') }}</span>
                    </div>
                  </div>
                  <div class="item-comment">
                    {{ item.komentar }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>

// import NavBar from "../navigation/NavBar";
import Footer from "../Footer";
import BlogService from "../../services/blog.service";

export default {
  name: "Slug",
  components: {Footer},
  data() {
    return {
      name: '',
      email: '',
      comment: '',
      commit: false
    }
  },
  computed: {
    slugs() {
      return this.$store.state.blog.slug;
    },
    archives() {
      return this.$store.state.blog.archives;
    },
    comments() {
      return this.$store.state.blog.comments;
    },
  },
  methods: {
    submit() {
      let loading = this.$loading.show();
      let params = {
        id_blog: sessionStorage.getItem('slug'),
        nama: this.name,
        email: this.email,
        komentar: this.comment,
        setuju: this.commit
      }
      BlogService.postComment(params).then(
          (response) => {
            loading.hide();
            if (response.code === 200) {
              this.email = '';
              this.name = '';
              this.comment = '';
              this.setuju = false
              this.$store.dispatch('blog/getComment', sessionStorage.getItem('slug'))
            } else {
              this.$swal.fire(
                  'Error',
                  response.message,
                  'error'
              );
            }
          }
      );
    },
  },
  mounted() {
    this.$store.dispatch('blog/setSlug', sessionStorage.getItem('slug'));
    this.$store.dispatch('blog/getComment', sessionStorage.getItem('slug'))
    this.$store.dispatch('blog/getCategories');
  }
}
</script>

<style scoped>
.blog-slug {
  margin-top: 100px;
}

.slug-title {
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #A9A9A9;
}

.slug-header {
  margin-top: 50px;
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.bottom-left {
  text-align: left;
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  text-align: left;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slug-body {
  margin-top: 70px;
}

.slug-body .slug-content {
  text-align: left;
}

.slug-content .content span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  color: #151515;
}


.slug-button-group a {
  border: 1px solid #FFFFFF;
  border-radius: 12px;
  margin: 5px;
  background-color: #F57F17;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.slug-button-group button {
  border: 1px solid #FFFFFF;
  border-radius: 12px;
  margin: 5px;
  background-color: #F57F17;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.list-comment-item {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 24px 0px;
}

.list-comment-item .item-comment {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #151515;
}

.list-comment-item .row h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #151515;
}

.list-comment-item .row .btn-tag {
  padding: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  color: #FFFFFF;
  background: #F57F17;
  border-radius: 12px;
}

.list-comment-item .row span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  color: #D1D1D1;

}
</style>