<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="salary-reduction">
      <div class="container">
        <Back/>
        <div class="mt-5 mb-5">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="mt-5">
                <h2>Pemotongan Gaji untuk pelunasan</h2>
                <h6>Anda akan terkena potongan gaji
                  Rp 1.000.000 dari peminjaman Rp 10.000.00
                  <br><span style="color: #B82E24;">Dengan melanjutkan, Anda setuju untuk mendapat potongan pada gaji bulan ini (sebelum tanggal 25)</span>
                </h6>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-right">
              <img src="~@/assets/images/PemotonganGaji.png" width="400px" alt="">
            </div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <button class="btn loan-btn-primary-outline w-25" @click="back">Tidak Setuju</button>
          <button class="btn loan-btn-primary w-25 ml-1" @click="submit">Setuju</button>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import Back from "../components/Back";
import router from "../router";

export default {
  name: "SalaryReduction",
  components: {Back, Footer},
  methods: {
    back() {
      router.back();
    },
    submit() {
      alert('Under development')
    }
  }
}
</script>

<style scoped>
.salary-reduction {
  margin-top: 100px;
}

.salary-reduction h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -1px;
  color: #1E0E62;
}

.salary-reduction h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}
</style>