<template>
  <div class="blog">
    <div class="image-header">
      <div class="text-center">
        <h3 class="title">Blog</h3>
      </div>
      <div class="container">
        <v-row>
          <v-col md="6" v-if="records.length > 0" >
              <v-img :src="records[0].ImagesPath" class="white--text align-end" gradient="to top, rgb(0, 0, 0) 1%, rgb(0, 0, 0, 0) 60%" style="width: 100%;border-radius: 12px" alt=""
                 @click="handleRead(records[0].id)">
                 <v-chip class="btn-tag2 w-auto">{{ records[0].Tags }}</v-chip>
                    <v-card-title class="mt-auto  mb-0">{{ records[0].TitleName }}</v-card-title>
                    <v-card-subtitle class="mt-auto mb-0">
                      <img src="~@/assets/images/logo.png" class="avatar" alt=""/> 
                      <span class="ml-4">Penulis : {{ records[0].WritenBy }} / {{ records[0].Createdate | moment('DD MMM YYYY') }}</span>
                    </v-card-subtitle>
              </v-img>
          </v-col>
          <v-col v-if="records.length > 1" md="6">
            <v-img :src="records[1].ImagesPath" class="white--text align-end" gradient="to top, rgb(0, 0, 0) 1%, rgb(0, 0, 0, 0) 60%" style="width: 100%;border-radius: 12px" alt=""
                 @click="handleRead(records[1].id)">
                 <v-chip class="btn-tag2 w-auto">{{ records[1].Tags }}</v-chip>
                  <v-card-title class="mb-4">{{ records[1].TitleName }}</v-card-title>
                  <v-card-subtitle class="pb-4">
                    <img src="~@/assets/images/logo.png" class="avatar" alt=""/> 
                    <span class="ml-4">Penulis : {{ records[1].WritenBy }} / {{ records[1].Createdate | moment('DD MMM YYYY') }}</span>
                  </v-card-subtitle>
              </v-img>
          </v-col>
        </v-row>
        <!-- <div class="row">
          <div class="col-md-6">
            <img :src="records[0].ImagesPath" class="h-100 img" style="width: 100%;border-radius: 12px" alt=""
                 @click="handleRead(records[0].id)">
            <button class="btn btn-tag2 w-auto">{{ records[0].Tags }}</button>
            <article>
              <p>{{ records[0].TitleName }}</p>
              <img src="~@/assets/images/logo.png" class="avatar" alt="">
              <span>Penulis : {{ records[0].WritenBy }} / {{ records[0].Createdate | moment('DD MMM YYYY') }}</span>
            </article>
          </div>
          <div v-if="records.length>1" class="col-md-6 col-lg-6">
            <img :src="records[1].ImagesPath" class="h-100" style="width: 100%;border-radius: 12px" alt=""
                 @click="handleRead(records[1].id)">
            <button class="btn btn-tag2 w-auto">{{ records[1].Tags }}</button>
            <article class="article-2">
              <p>{{ records[1].TitleName }}</p>
              <img src="~@/assets/images/logo.png" class="avatar" alt="">
              <span>Penulis : {{ records[1].WritenBy }} / {{ records[1].Createdate | moment('DD MMM YYYY') }}</span>
            </article>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";

export default {
  name: "HeaderNews",
  props: {
    records: Array
  },
  methods: {
    handleRead(slug) {
      sessionStorage.setItem('slug', JSON.stringify(slug));
      this.$store.dispatch('blog/setSlug', slug).then(() => {
        router.push('/blog/slug');
      });
    }
  }
}
</script>

<style>
.bg-primary{
  background-color: aqua !important;
}
</style>