<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="self-payment">
      <div class="container">
        <Back/>
        <div class="content m-5">
          <h2>Lakukan pembayaran</h2>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="mt-5">
                <h4>Total yang harus dibayar</h4>
                <h5>Rp {{ currency(statusLoan.TotalPelunasan) }}</h5>
                <h4>Nomor virtual account</h4>
                <h5>BCA - 2323929392</h5>
                <h4>Nama rekening</h4>
                <h5>Bakti Fajar Idaman</h5>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="bank mt-5">
                <h4>Cara pembayaran</h4>
                <div class="panel-group" id="accordion">
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <div class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1" class="text-left">
                          ATM BCA <span class="text-right"><b-icon icon="chevron-down"
                                                                   font-scale="1"></b-icon></span></a>
                      </div>
                    </div>
                    <div id="collapse1" class="panel-collapse collapse in">
                      <div class="panel-body">
                        <ul class="text-left">
                          <li>Masukkan kartu ATM BCA atau Paspor BCA Anda.</li>
                          <li>Pilih Bahasa Indonesia dan masukan PIN ATM dengan benar.</li>
                          <li>Pilih menu Transaksi Lainnya, kemudian pilih transfer ke rekening BCA.</li>
                          <li>Masukkan nomor rekening 2323929392.</li>
                          <li>Masukkan jumlah nominal uang yang akan dikirim.</li>
                          <li>Layar ATM menampilkan data transaksi yang akan diproses. Tekan YA bila semua sudah
                            benar.
                          </li>
                          <li>Tunggu hingga transaksi selesai.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <div class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                          KlikBCA Individu<span class="text-right"><b-icon icon="chevron-down"
                                                                           font-scale="1"></b-icon></span></a>
                      </div>
                    </div>
                    <div id="collapse2" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="text-left">
                          <li>Daftarkan terlebih dahulu nomor rekening 2323929392 di KlikBCA Individual</li>
                          <li>Buka menu "Transfer Dana"</li>
                          <li>Pilih Transfer ke "Rekening BCA"</li>
                          <li>Pilih nomor rekening dari daftar transfer yang sudah didaftarkan</li>
                          <li>Masukan nominal transfer Tulis berita jika ada informasi terkait transfer</li>
                          <li>Akan muncul 8 angka di bagian bawah yang harus dimasukan ke KeyBCA. Nyalakan KeyBCA,
                            masukan pin dan tekan angka 2.
                          </li>
                          <li>Masukan 8 angka yang muncul. Kemudian masukan 8 angka balasan pada Respon KeyBCA APLLI 2
                          </li>
                          <li>Klik "Lanjutkan"</li>
                          <li>Lalu muncul identitas rekening tujuan</li>
                          <li>Aktifkan kembali KeBCA dan tekan nomor 1</li>
                          <li>Muncul 8 angka untuk diinput di kotak "Respon KeyBCA APPLI 1"</li>
                          <li>Klik "Kirim"</li>
                          <li>Transaksi berhasil dan akan muncul bukti transaksi</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <div class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                          m-BCA<span class="text-right"><b-icon icon="chevron-down"
                                                                font-scale="1"></b-icon></span></a>
                      </div>
                    </div>
                    <div id="collapse3" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="text-left">
                          <li>Masuk ke menu m-Transfer.</li>
                          <li>Selanjutnya, pilih transfer ke bank BCA.</li>
                          <li>Setelah itu, masukkan nomor rekening 2323929392, lalu masukkan jumlah uang yang akan kamu
                            transfer.
                          </li>
                          <li>Dengan transfer menggunakan Mobile Banking BCA, kamu juga dapat menambahkan berita atau
                            keterangan transfer di akhir step.
                          </li>
                          <li>Kemudian kamu akan melihat menu konfirmasi nomor rekening penerima. Setelah itu kamu
                            diminta memasukkan Personal Identification Number (PIN) BCA, pastikan tidak salah dalam
                            memasukkan PIN
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <div class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse4">
                          Kantor BCA<span class="text-right"><b-icon icon="chevron-down"
                                                                     font-scale="1"></b-icon></span></a>
                      </div>
                    </div>
                    <div id="collapse4" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="text-left">
                          <li>Ambil nomor antrian transaksi Teller dan isi slip setoran</li>
                          <li>Serahkan slip dan jumlah setoran kepada Teller BCA</li>
                          <li>Teller BCA akan melakukan validasi transaksi</li>
                          <li>Simpan slip setoran hasil validasi sebagai bukti pembayaran</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container text-center m-5">
            <img src="~@/assets/images/PembayaranMandiri.png" class="mt-5" width="400px" alt="">
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Back from "../components/Back";
import Footer from "../components/Footer";
import Utils from '@/helper';

export default {
  name: "SelfPayment",
  components: {Footer, Back},
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    statusLoan() {
      return this.$store.state.loan.status;
    }
  },
  methods: {
    currency(nominal) {
      return Utils.currencyRp(nominal);
    }
  },
  mounted() {
    this.$store.dispatch('loan/getStatusPelunasan', this.user.NIK);
  }
}
</script>

<style scoped>
.self-payment {
  margin-top: 100px;
}

.self-payment .content h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.self-payment .content h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.self-payment .content h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #F57F17;
  border-radius: 100px;
}

.self-payment .content .bank div .panel-title {
  width: 100%;
  display: block;
  padding-top: 10px;
  margin-top: 10px;
  color: #555;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 3px;
  border-bottom: 2px solid #EBEAED;
}

.self-payment .content .bank div .panel-title a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;;
  color: rgba(21, 20, 57, 0.4);
}

.panel-title a span {
  display: block;
  color: #555;
  margin-top: -30px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 3px;
  text-decoration: none;
}

.self-payment .bank .panel-body {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: rgba(21, 20, 57, 0.4);
}
</style>