<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="blog">
      <section class="product-blog d-none d-md-block d-sm-block">
          <header-news v-if="newest" :records="newest"/>
      </section>
      <br>
      <section class="mt-16">
        <div class="container">
          <div class="row">
            <div class="col-3">
              <div class="row">
                <div class="col-12 text-left">
                  <ul style="list-style: none;">
                    <li>
                      <h4>Archives</h4>
                    </li>
                    <li v-for="(item,index) in archives" :key="index"><a
                        href="#" @click.prevent="handleSearch('archive',item.archive)">{{
                        item.archive | moment('MMMM YYYY')
                      }}</a></li>
                  </ul>
                </div>
              </div>
              <div class="row d-none d-md-block d-sm-block">
                <div class="col-12 text-left">
                  <ul class="category" style="list-style: none;">
                    <li>
                      <h4>Category</h4>
                    </li>
                    <li v-for="(item,index) in categories" :key="index">
                      <a href="#" @click.prevent="handleSearch('Category',item.Category)">{{ item.Category }}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-9 col-12">
              <Thumbnail v-if="blogs" :records="blogs"/>
            </div>
          </div>
        </div>
      </section>
      <section class="pagination text-center">
        <div class="container">
          <div class="row">
            <div class="col-md-4 text-left d-none d-md-block d-sm-block">
              <div class="table-pagination py-4 mt-3">
                <h5>Halaman {{ pagination.currentPage }}</h5>
              </div>
            </div>
            <div class="col-md-4 col-12 text-center">
              <div class="table-pagination py-4">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item">
                      <a class="page-link" href="#"
                         v-on:click.prevent="prevPage($event)">Sebelumya</a>
                    </li>
                    <li class="page-item disabled"><a class="page-link" href="#" disabled>{{
                        pagination.currentPage
                      }}</a>
                    </li>
                    <li class="page-item disabled"><a class="page-link" href="#" disabled>/</a></li>
                    <li class="page-item disabled"><a class="page-link" href="#" disabled>{{
                        pagination.lastPage
                      }}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#"
                         v-on:click.prevent="nextPage($event)">Berikutnya</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="col-md-4 text-righ d-none d-md-block d-sm-blockt">
              <div class="table-pagination py-4 mt-3">
                <h5>{{ pagination.total }} artikel</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import HeaderNews from "../components/blogs/HeaderNews.vue";
import Thumbnail from "../components/blogs/Thumbnail.vue";
// import Subscribe from "../components/blogs/Subscribe";
import BlogService from "../services/blog.service";

export default {
  name: "Blog",
  data() {
    return {
      blogs: [],
      searchBy: 'TitleName',
      searchParam: '',
      limit: 6,
      records: [6, 10, 30, 50, 100, 500, 1000],
      pagination: {
        perPage: 10,
        currentPage: 1,
        lastPage: '',
        nextPageUrl: '',
        prevPageUrl: '',
        total: 0
      },
    }
  },
  components: {Thumbnail, HeaderNews, Footer},
  computed: {
    newest() {
      return this.$store.state.blog.newest;
    },
    archives() {
      return this.$store.state.blog.archives;
    },
    categories() {
      return this.$store.state.blog.categories;
    }
  },
  methods: {
    getPaginate(searchBy, searchParam, limit, page) {
      let loading = this.$loading.show();
      BlogService.getPagination(searchBy, searchParam, limit, page).then(response => {
        loading.hide()
        this.blogs = response.data.data
        this.pagination = {
          currentPage: response.data.current_page,
          perPage: response.data.per_page,
          lastPage: response.data.last_page,
          prevPageUrl: response.data.prev_page_url,
          nextPageUrl: response.data.next_page_url,
          total: response.data.total
        }
      });
    },
    //Search Record by Warehouse name
    handleSearch(searchBy, searchParam) {
      this.getPaginate(searchBy, searchParam, this.limit, this.pagination.currentPage)
    },
    //Prev Pagination
    prevPage() {
      if (this.pagination.prevPageUrl) {
        this.pagination.currentPage--;
        this.getPaginate(this.searchBy, this.searchParam, this.limit, this.pagination.currentPage)
      }
    },

    //Next Pagination
    nextPage() {
      if (this.pagination.nextPageUrl) {
        this.pagination.currentPage++;
        this.getPaginate(this.searchBy, this.searchParam, this.limit, this.pagination.currentPage)
      }
    },
    //Search Record
    handleChange() {
      if (this.searchBy === 'All') {
        this.searchParam = '';
        this.getPaginate(this.searchBy, this.searchParam, this.limit, 1)
      }
    },
  },
  mounted() {
    this.$store.dispatch('blog/getNewest');
    this.getPaginate(this.searchBy, this.searchParam, this.limit, this.pagination.currentPage)
    this.$store.dispatch('blog/getArchive');
    this.$store.dispatch('blog/getCategories');
  }
}
</script>

<style scoped>
.blog .pagination h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #A9A9A9;
}
</style>