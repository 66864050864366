<template>
  <!-- Header -->
  <header class="bg-header">
    <div class="container banner">
      <div class="row">
        <div class="col-md-6 col-12">
          <h1>Segera hadir <br> e-commerce!</h1>
          <p> Bakti Fajar Idaman akan segera meluncurkan fitur <br>
            tebarunya yakni jual-beli online dan barang yang <br>
            dibeli bisa langsung diantar ke lokasi Anda, lho! <br>
            Tunggu tanggal releasenya ya!</p>
          <div class="row header-info mt-12">
            <div class="col-md-6 col-12 text-center">
              <v-card class="pa-6 rounded-circle ml-auto mr-auto mb-4" color="yellow" width="100">
                <v-img src="~@/assets/images/icons/kartunama.png" alt=""></v-img>
              </v-card>

              <h3>Eksklusif untuk Anggota</h3>
              <p class="mt-3">
                E-commerce hanya bisa <br> diakses oleh Anggota Bakti <br>
                Fajar Idaman dengan NIK
              </p>
            </div>
            <div class="col-md-6 col-12 text-center">
              <v-card class="pa-6 rounded-circle ml-auto mr-auto mb-4" color="yellow" width="100">
                <v-img src="~@/assets/images/icons/diskon.png" alt=""></v-img>
              </v-card>
              
              <h3>Eksklusif untuk Anggota</h3>
              <p class="mt-3">
                Di e-commerce ini Anggota <br> bisa mendapatkan penawaran <br> special yang menarik
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block d-sm-block">
          <img src="~@/assets/images/hp.png" class="macbook" alt="">
        </div>
      </div>
    </div>
  </header>

</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>