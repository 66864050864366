<template>
  <div class="index">
    <!-- <nav-bar/> -->
    <Header/>
    <section class="section-about">
      <div class="container text-center">
        <h1>Tentang Kami</h1>
        <p class="des">Bakti Fajar Idaman adalah sebuah organisasi ekonomi yang dimiliki dan <br> dioperasikan oleh
          orang-seorang
          demi kepentingan bersama.</p>
        <div class="row mt-5">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="card">
              <div class="card-body text-center">
                <img src="~@/assets/images/icons/hotel.png" alt="">
                <h4 class="mt-5">Berdiri sejak 2012</h4>
                <p class="des">Bakti Fajar Idaman telah berdiri sejak tahun 2012, dan telah memiliki Nomor
                  Induk
                  Koperasi</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="card">
              <div class="card-body text-center">
                <img src="~@/assets/images/icons/duit.png" alt="">
                <h4 class="mt-5">> 5000 Anggota</h4>
                <p class="des">Anggota Koperasi berasal dari pegawai BFI yang masih aktif, dengan jumlah
                  anggota saat ini sebanyak lebih dari 5000 orang</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="card">
              <div class="card-body text-center">
                <img src="~@/assets/images/icons/keranjang.png" alt="">
                <h4 class="mt-5">Pinjaman & e-commerce</h4>
                <p class="des">Bakti Fajar Idaman berfokus pada pinjaman kepada anggota dan e-commerce
                  untuk
                  anggota</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Produk/>
    <Promo/>
    <Blog/>
    <Apps/>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar.vue";
import Header from "../components/Header.vue";
import Blog from "../components/Blog.vue";
import Apps from "../components/Apps.vue";
import Produk from "../components/Produk.vue";
import Footer from "../components/Footer";
import Promo from '../components/Promo.vue';

export default {
  name: "Index",
  components: {Footer, Apps, Blog, Produk, Promo, Header}
}
</script>

<style scoped>

</style>