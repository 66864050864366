<template>
  <v-btn outlined @click="back" rounded color="white">
    <v-icon>mdi-chevron-left</v-icon>
    Back</v-btn>
  <!-- <button @click="back" class="btn loan-btn-primary-outline">
    <b-icon icon="chevron-left" font-scale="0.9"></b-icon>
    Back
  </button> -->
</template>

<script>
import router from "../router";

export default {
  name: "Back",
  methods: {
    back() {
      router.back();
    }
  }
}
</script>

<style scoped>

</style>