<template>
  <div class="produk">
    <!-- <NavBar/> -->
    <div class="about">
      <section class="mt-16">
        <div class="container">
          <v-row>
            <v-col cols="12" class="text-center">
              <h3 class="mt-5" color="#1E0E62">Produk</h3>
            </v-col>
            <v-col md="6">
              <v-img src="~@/assets/images/produk/bfm.png"></v-img>
              <div class="mt-8">
                <h4>Bakti Fajar Mart</h4>
                <p class="des">Bakti Fajar Idaman adalah sebuah organisasi ekonomi yang dimiliki dan dioperasikan oleh orang-seorang demi kepentingan bersama.</p>
              </div>
            </v-col>
            <v-col md="6">
              <v-img src="~@/assets/images/produk/koperasi.png"></v-img>
              <div class="mt-8">
                <h4>Simpan Pinjam</h4>
                <p class="des">Bakti Fajar Idaman adalah sebuah organisasi ekonomi yang dimiliki dan dioperasikan oleh orang-seorang demi kepentingan bersama.</p>
              </div>            </v-col>
          </v-row>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Produk",
  data() {
    return {
    }
  },
}
</script>
