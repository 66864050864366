const loadings = {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    setLoading ({ commit }, payload) {
      commit('SET_LOADING', payload)
    }
  }
}

export default loadings
