import BlogService from "@/services/blog.service";

const blog = {
    namespaced: true,
    state: {
        blogs: [],
        archives: [],
        categories: [],
        newest: [],
        slug: JSON.parse(sessionStorage.getItem('slug')),
        comments: []
    },
    actions: {
        getAll({commit}) {
            BlogService.getAll().then((response) => {
                commit('SET_BLOG_RECORDS', response.data);
            })
        },
        getNewest({commit}) {
            BlogService.getNewest().then((response) => {
                commit('SET_NEWEST_RECORDS', response.data);
            })
        },
        getArchive({commit}) {
            BlogService.getArchive().then((response) => {
                commit('SET_ARCHIVE_RECORDS', response.data);
            })
        },
        getCategories({commit}) {
            BlogService.getCategories().then((response) => {
                commit('SET_CATEGORIES_RECORDS', response.data);
            })
        },
        setSlug({commit}, slug) {
            BlogService.getShow(slug).then((response) => {
                commit('SET_SLUG', response.data);
            })
        },

        getComment({commit}, id) {
            BlogService.getComment(id).then((response) => {
                commit('SET_COMMENTS', response.data);
            })
        }
    },
    mutations: {
        SET_BLOG_RECORDS(state, data) {
            state.blogs = data;
        },
        SET_NEWEST_RECORDS(state, data) {
            state.newest = data;
        },
        SET_ARCHIVE_RECORDS(state, data) {
            state.archives = data;
        },
        SET_CATEGORIES_RECORDS(state, data) {
            state.categories = data;
        },
        SET_SLUG(state, data) {
            state.slug = data;
        },
        SET_COMMENTS(state, data) {
            state.comments = data;
        }
    }
};

export default blog;