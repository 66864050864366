<template>
  <div class="saldo bg-header-money d-none d-sm-block"><br>
    <h1 class="text-center">Selamat Datang di Solusi Kita</h1>
    <div v-if="!loanStatus">
      <div class="saldo-amount">
        <h2 class="text-center">Anda belum mengajukan pinjaman</h2>
      </div>
      <div class="no-loan-wrapper">
        <div class="container text-center">
          <img src="@/assets/images/no-loan.png" alt="no-loan">
        </div>
      </div>
    </div>
    <div v-if="loanStatus">
      <div class="saldo-amount">
        <h2 class="text-center">Pinjaman saat ini Rp {{ currency(loanStatus.LoanAmount) }}</h2>
      </div>
      <div class="saldo-wrapper2">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-ms-6 col-12">
            <div class="saldo-thumbnail">
              <img src="~@/assets/images/icons/terbayarkan.png" alt="">
              <h6>Terbayarkan</h6>
              <h4>Rp. {{ currency(loanStatus.Terbayarkan) }}</h4>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-ms-6 col-12">
            <div class="saldo-thumbnail">
              <img src="~@/assets/images/icons/sisa-saldo.png" alt="">
              <h6>Sisa Saldo</h6>
              <h4>Rp. {{ currency(loanStatus.SisaSaldo) }}</h4>
            </div>
          </div>
        </div>
        <!-- <div class="container text-center">
          <router-link class="btn loan-btn-primary" to="/repayment" v-if="loanStatus.Status">Lakukan Pelunasan
          </router-link>
          <router-link class="btn loan-btn-primary" to="/repayment" v-if="!loanStatus.Status" disabled="true">Lakukan
            Pelunasan
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/helper';

export default {
  name: "Saldo",
  props: {
    nik: String
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
    loanStatus() {
      return this.$store.state.loan.status;
    }
  },
  methods: {
    currency(nominal) {
      return Utils.currencyRp(nominal);
    }
  }
}
</script>

<style scoped>

.saldo-amount {
  padding-top: 50px;
  position: relative;
  left: 0;
  right: 0;
}

.saldo-wrapper {
  width: 720px;
  height: 460px !important;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 100;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  display: table;
  box-shadow: 0px 1px 1px rgba(47, 48, 53, 0.1), 0px 2px 6px rgba(47, 48, 53, 0.1);
  border-radius: 16px;
}
.saldo-wrapper2 {
  width: 720px;
  height: 400px !important;
  background-color: #fff;
  position: absolute;
  margin-top: 13vmax !important;
  /* margin-bottom: 70px !important; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  display: table;
  box-shadow: 0px 1px 1px rgba(47, 48, 53, 0.1), 0px 2px 6px rgba(47, 48, 53, 0.1);
  border-radius: 16px;
}

.saldo h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #2F3035
}

.saldo-thumbnail {
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 70px;
}

.saldo-thumbnail h6 {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}

.saldo-thumbnail h4 {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #1E0E62;
}

.no-loan-wrapper {
  width: 720px;
  height: 250px;
  position: absolute;
  left: 0;
  right: 0;
  top: 200px;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  display: table;
}
</style>