<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="forget-password bg-header">
      <div class="div-center">
        <div class="content">
          <h3 class="text-center m-4">Lupa Password</h3>
          <hr>
          <h6 class="text-center">Silahkan masukkan NIK Anda untuk reset password</h6>
          <form role="form" @submit.prevent="handleOpt">
            <div class="form-group m-5">
              <input type="text" class="form-control loan-input-control" v-model="nik" id="nik" placeholder="NIK"
                     required>
            </div>
            <div class="form-group m-5">
              <input type="submit" class="btn loan-btn-primary btn-block" value="Masukan"/>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";

export default {
  name: "ForgetPassword",
  // components: {NavBar},
  data() {
    return {
      nik: ''
    }
  },
  methods: {
    handleOpt() {
      let loading = this.$loading.show();
      this.$store.dispatch("otp/getOtp", this.nik).then(() => {
            this.$router.push("/otp");
            loading.hide();
          },
      );
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.isLogin) {
      this.$router.push("/home");
    }
  },
}
</script>

<style scoped>

</style>