<template>
    <div v-if="!loanStatus" class="no-loan">
      <div class="no-loan">
        <div class="container text-center">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-ms-6 col-12">
              <div class="apply-loan">
                <h6>Anda belum meminjam</h6>
                <h2 class="justify-content-center">Ajukan pinjaman sekarang!</h2>
                <router-link v-if="inquiry.Status != 'Sedang dalam approval'" to="/application-form" class="btn loan-btn-primary mt-5">Ajukan pinjaman</router-link>
                <router-link v-else to="#" class="btn loan-btn-primary mt-5"  data-toggle="modal" data-target="#exampleModal">Ajukan pinjaman</router-link>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-ms-6 col-12">
              <div>
                <img src="~@/assets/images/AjukanPinjaman.png" alt="" width="400px">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Info</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Pengajuan anda sedang dalam approval
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import LoanService from '../../services/loan.service';
export default {
  name: "NoLoan",
  computed: {
    loanStatus() {
      return this.$store.state.loan.status;
    }
  },
  data(){
    return {
      inquiry: [],
    }
  },
  created() {
    LoanService.getInquiryStatus(this.$store.state.auth.user.user.NIK)
        .then((response) => {
          this.inquiry = response.data;
          console.log(this.inquiry.Status);
        })
        .catch((error) => {
          console.log("Eror Data Tidak Di Temukan", error.response);
        });
  },
}
</script>

<style scoped>
.no-loan{
  border-bottom: 1px solid #FFEB3B;
}
.apply-loan{
  margin-top: 100px;
}

.apply-loan h6{
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.apply-loan h2{
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

</style>