import Vuex from 'vuex'
import Vue from 'vue'
import blog from "./blog.module";
import ochart from "./ochart.module";
import {auth} from "./auth.module";
import product from "./product.module";
import promotion from "./promotion.module";
import faq from "./faq.module";
import loan from "./loan.module";
import notification from "./notification.module";
import loading from './loading.module';
import otp from "./otp.module";
import point from "./point.module";
import axios from "axios";
import Host from '@/api/host'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        key: [],
    },
    actions: {
        reqKey({ commit }) {
            return axios.get(`${Host.LOAN_V1}/get-key/math`).then(({ data }) => {
                commit("SET_KEY", data);
            });
        },
    },
    mutations: {
        SET_KEY(state,key){
            state.key = key;
        },
    },
    getters: {
        getKey(state){
          return state.key;
        }
    },
    modules: {
        point,
        otp,
        notification,
        loan,
        faq,
        promotion,
        product,
        blog,
        ochart,
        auth,
        loading
    },
});