import OtpService from "@/services/otp.service";

const otp = {
    namespaced: true,
    state: {
        data: []
    },
    actions: {
        getOtp({commit}, nik) {
            return OtpService.getOTP(nik).then((response) => {
                if (response.code === 200) {
                    let data = {
                        nik: nik,
                        id: response.data.id
                    }
                    commit('SET_RECORD', data);
                }
                return Promise.resolve(response)
            })
        }
    },
    mutations: {
        SET_RECORD(state, data) {
            state.data = data;
        }
    }
};

export default otp;