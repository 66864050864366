<template>
  <div v-if="loanStatus && loanStatus.Status !== '0' " class="my-loan">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-12">
          <h3>Pinjaman dalam proses</h3>
        </div>
        <div class="col-md-6 col-12 float-right">
          <button class="btn btn-success float-right mt-3" style="border-radius: 10px" disabled>{{
              loanStatus.Status
            }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="row mt-5">
            <div class="col-6">
              <div class="my-saldo-thumbnail">
                <img :src="loanStatus.ImagesPath" alt="">
                <h6>Tujuan</h6>
                <h4>{{ loanStatus.FinancePurpose }}</h4>
              </div>
            </div>
            <div class="col-6">
              <div class="my-saldo-thumbnail">
                <img src="~@/assets/images/icons/Jumlah-Pinjaman.png" alt="">
                <h6>Jumlah Pinjaman</h6>
                <h4>Rp {{ currency(loanStatus.LoanAmount) }}</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="my-saldo-thumbnail">
                <img src="~@/assets/images/icons/Tenor.png" alt="">
                <h6>Tenor</h6>
                <h4>{{ loanStatus.Tenor }} Bulan</h4>
              </div>
            </div>
            <div class="col-6">
              <div class="my-saldo-thumbnail">
                <img src="~@/assets/images/icons/Cicilan.png" alt="">
                <h6>Cicilan /Bulan</h6>
                <h4>Rp {{ currency(loanStatus.InstallmentAmount) }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="row mt-5">
            <div class="my-saldo-ilus">
              <img :src="require('@/assets/images/PinjamanBerlangsungNew.png')" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/helper';

export default {
  name: "MyLoan",
  props: {
    nik: String
  },
  methods: {
    currency(nominal) {
      return Utils.currencyRp(nominal);
    }
  },
  computed: {
    loanStatus() {
      return this.$store.state.loan.inquiries;
    }
  }
}
</script>

<style scoped>
.my-loan {
  margin-top: 50px;
}

.my-loan h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.my-loan h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #1E0E62;
  mix-blend-mode: normal;
}

.my-saldo-thumbnail {
  text-align: left;
  margin: auto;
  width: 100%;
  padding-top: 70px;
  cursor: pointer;
}

.my-saldo-thumbnail img {
  width: 100px;
}

.my-saldo-thumbnail img:hover {
  width: 110px;
}

.my-saldo-thumbnail h6 {
  text-align: left;
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #1E0E62;
}

.my-saldo-thumbnail h4 {
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #1E0E62;
}

.my-saldo-ilus img {
  text-align: left;
  margin: auto;
  width: 100%;
  padding-top: 30px;
}
</style>