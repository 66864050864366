<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <!-- <v-app> -->
    <div class="bg-header">
      <div class="container">
        <div class="row container-login">
          <div class="col-md-6">
            <div class="judul">SOLUSI <br>KITA</div>
            <hr class="garis"/>
            <p class="kalimat">
              Solusi bagi kita semua by Koperasi Bakti Fajar Idaman 
            </p>
          </div>
          <div class="col-md-6">
            <div class="div-center logins">
              <div class="login content"><br>
                <h3 class="text-center">MASUK</h3><br>
                <form @submit.prevent="onLogin()">
                  <div class="">
                    <!-- <label for="nik">NIK</label> -->
                    <v-text-field
                      label="NIK"
                      placeholder="NIK"
                      v-model="form.nik"
                      append-icon="mdi-account"
                      outlined
                      dense
                    ></v-text-field>
                    <!-- <v-text-field
                      v-model="form.nik"
                      solo
                      label="NIK"
                      clearable
                    ></v-text-field> -->
                    <!-- <span style="display:flex;"> 
                    <input type="text" class="form-control loan-input-control" id="nik" v-model="form.nik" placeholder="NIK"
                          required>
                        <v-btn icon  class="hide-password">
                          <v-icon>mdi-account</v-icon>
                        </v-btn>
                      </span> -->
                  </div>
                  <div class="">
                    <v-text-field
                      label="Password"
                      placeholder="Password"
                      v-model="form.password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show ? 'text' : 'password'"
                      outlined
                      dense
                      @click:append="show = !show"
                    ></v-text-field>
                    <!-- <label for="exampleInputPassword1">Password</label>
                    <span style="display:flex;"> 
                        <input  :type="inputType" class="form-control loan-input-control" v-model="form.password" placeholder="Password">
                        <v-btn icon class="hide-password">
                          <v-icon @click='ShowPassword()'>{{ iconEye }}</v-icon>
                        </v-btn>
                      </span> -->
                    <!-- <input type="password" class="form-control loan-input-control" v-model="password"
                          id="exampleInputPassword1"
                          prepend-icon="mdi-database-search"
                          placeholder="Password" required>
                          <div class="control">
                                    <button class="button" @click="toggleShow"><span class="icon is-small is-right">
                                              <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                            </span>
                                    </button>
                          </div> -->
                          <!-- <v-icon>mdi-eye-outline</v-icon> -->
                  </div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-md-6">
                        <img :src="captcha.img" width="100%" style="height: 40px;" @click="changeCp()" alt="">
                      </div>
                      <div class="col-md-6">
                        <v-text-field
                          label="Hasil"
                          placeholder="Hasil"
                          v-model="form.captcha"
                          append-icon="mdi-lock"
                          outlined
                          dense
                        ></v-text-field>
                        <!-- <input type="text" v-model="form.captcha" class="input-captcha" style="width: 50px !important;border: 1;" id="inputPassword" placeholder="Hasil"> -->
                      </div>
                    </div>
                  </div>
                  <input type="submit" class="btn loan-btn-secondary btn-block" value="Login">
                  <hr/>
                  <div class="text-center">
                    <span>Lupa Password ? <router-link to="/forget-password">Klik disini</router-link> </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    </div>
    <!-- </v-app> -->
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
// import VueRecaptcha from "vue-recaptcha";
import Footer from "../components/Footer";
export default {
  name: "LoginV2",
  components: {Footer},
  data() {
    return {
      show: false,
      inputType: 'password',
      iconEye: 'mdi-eye-outline',
      form: {
          nik: '',
          password: '',
          captcha: ''
      },
      isMoving: false,
      x: 0,
      isPassing: false,
      margin_left: "0",
      panjang:0,
      proses: true
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.auth.status.loggedIn;
    },
    captcha(){
            return this.$store.getters.getKey
      },
  },
  created() {
    if (this.isLogin) {
      this.$router.push("/home");
    }else{
      this.$store.dispatch("reqKey");
    }
  },
  methods: {
      changeCp(){
          this.$store.dispatch("reqKey");
      },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    ShowPassword(){
      this.inputType = this.inputType === "password" ? "text" : "password";
      this.iconEye = this.iconEye === 'mdi-eye-outline' ?  "mdi-eye-off-outline" : "mdi-eye-outline"; 
    },
    onLogin() {
      this.proses = false
      const loading = this.$loading.show();
      this.margin_left = "0"
      // let user = {
      //   nik: this.nik,
      //   password: this.password
      // }
      this.$store.dispatch("auth/login", this.form).then(
          (response) => {
            setTimeout(() => {
              this.proses = true
            }, 2000);
            loading.hide();
            if (response.code === 200) {
              this.$router.push("/home");
            } else {
              loading.hide();
              this.$store.dispatch("reqKey");
            }

          }, () => {
            setTimeout(() => {
              this.proses = true
            }, 2000);
            this.$store.dispatch("reqKey");
            loading.hide();
          }
      );
    },
  },
}
</script>

<style scoped>
/* Header */
.login .div-center h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.login span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
}

.login span a {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  color: #1E0E62;
}

.login .re-captcha {
  margin-left: 35px !important;
  margin-bottom: 15px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .login .re-captcha {
    margin-left: 50px !important;
    margin-bottom: 15px;
  }
}

.input-captcha{
  border-radius: 5px;
  border: groove;
  border-color: #EBEAED;
}
</style>
