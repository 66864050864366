<template>
  <div class="loan">
    <!-- <nav-bar/> -->
    <div class="about">
      <div class="container">
        <div class="row">
          <About />
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import About from "../components/About.vue";

export default {
  name: "about",
  components: {Footer, About}
}
</script>

<style scoped>

</style>