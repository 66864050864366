<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="application-form-process">
      <div class="container">
        <div class="mt-5 mb-5">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
              <h2>Pinjaman Anda berhasil diajukan</h2>
              <h6>Pengajuan pinjaman Anda sedang menunggu persetujuan.</h6>
              <span>Persetujuan pengajuan pinjaman Anda akan disampaikan melalui SMS/e-mail</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <img src="~@/assets/images/PinjamanDiajukanNew.png" width="400" alt="">
            </div>
          </div>
          <div class="row loan-detail">
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="loan-process-item">
                <img src="~@/assets/images/icons/JumlahPinjaman.png" width="80" alt="">
                <h5 class="mt-4">Rp {{ currency(loan.LoanAmount) }}</h5>
                <h6 class="mt-2">Jumlah pinjaman yang Anda ajukan sebesar Rp {{ currency(loan.LoanAmount) }}</h6>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="loan-process-item">
                <img src="~@/assets/images/icons/JumlahTenor.png" width="80" alt="">
                <h5 class="mt-4">{{ loan.Tenor }} BULAN</h5>
                <h6 class="mt-2">Jangka waktu pelunasan pinjaman Anda adalah selama {{ loan.Tenor }} bulan</h6>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="loan-process-item">
                <img src="~@/assets/images/icons/jumlahCicilan.png" width="80" alt="">
                <h5 class="mt-4">Rp {{ currency(loan.InstallmentAmount) }} /bulan</h5>
                <h6 class="mt-2">Anda akan terkena potongan gaji sebesar Rp {{ currency(loan.InstallmentAmount) }}
                  selama {{ loan.Tenor }} bulan</h6>
              </div>
            </div>
          </div>
          <div class="container mt-5 mb-5 text-center">
            <a class="btn loan-btn-primary w-25 mt-5" href="/home">Laman Pinjaman</a>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import router from "../router";
import Utils from '@/helper';

export default {
  name: "LoanApplicationFormProcess",
  components: {Footer},
  computed: {
    loan() {
      return this.$store.state.loan.myloan;
    }
  },
  mounted() {
    if (!this.loan) router.back();
  },
  methods: {
    currency(nominal) {
      return Utils.currencyRp(nominal);
    },
    back() {
      router.push('/application-form');
    }
  }
}
</script>

<style scoped>
.application-form-process {
  margin-top: 100px;
}

.application-form-process h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: -1px;
  color: #1E0E62;
}

.application-form-process h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}

.application-form-process span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: red;
  mix-blend-mode: normal;
}

.loan-detail {
  text-align: center;
  padding-top: 150px;
}

.loan-detail .loan-process-item h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.loan-detail .loan-process-item h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}
</style>