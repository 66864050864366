import Api from '@/api'
import Host from '@/api/host'

class CalculatorService {
    postCalculate(params) {
        return Api.doPost(Host.LOAN_V1 + '/Calculator',params);
    }

    postChechRate(params){
        return Api.doPost(Host.LOAN_V1 + '/cekrate',params);
    }
}

export default new CalculatorService();