import PromotionService from "@/services/promotion.service";

const promotion = {
    namespaced: true,
    state: {
        data: []
    },
    actions: {
        getAll({commit}) {
            PromotionService.getAll().then((response) => {
                commit('SET_RECORDS', response.data);
            })
        }
    },
    mutations: {
        SET_RECORDS(state, data) {
            state.data = data;
        }
    }
};

export default promotion;