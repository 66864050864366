<template>
  <!-- Apps -->
  <section class="section-download mt-5">
    <div class="container text-center">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <h1>Download App Bakti Fajar Idaman <br> untuk pengajuan pinjaman</h1>
          <img class="mb-5 mt-4 gp" src="~@/assets/images/googleplay.png" alt="">
          <div class="iphone">
            <img src="~@/assets/images/apps.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Apps"
}
</script>

<style scoped>

</style>