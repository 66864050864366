<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="account">
      <div class="container">
        <h1>Account</h1>
        <div class="mt-5 mb-5">
          <div class="account-image text-center">
            <div class="image-upload text-center">
              <v-row width="100%">
                <v-col cols="12" class="image-upload ml-auto mr-auto">
                  <label for="file-input1">
                  <v-img
                    v-if="!user.image"
                    src="~@/assets/images/Avatar.png"
                    class="rounded-circle ml-auto mr-auto"
                    width="150"
                    height="150"
                    style="background-color: #444444;cursor: pointer;"
                />
                  <v-img
                      v-if="user.image"
                      :src="user.image"
                      class="rounded-circle ml-auto mr-auto"
                      width="150"
                      height="150"
                      style="background-color: #444444;cursor: pointer;"
                  />
                </label>
                <input
                  id="file-input1"
                  @change="onFileChange"
                  accept="image/*"
                  type="file"
              />
                </v-col>
              </v-row>
            </div>
            <h2>{{ user.name }}</h2>
            <h6>{{ user.NIK }}</h6>
            <button class="btn loan-btn-primary-outline" @click="handleChangeProfile">Upload Foto</button>
          </div>
          <div class="container account-detail">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/JumlahTenor.png" width="20" alt="date" class="mr-2">Tanggal lahir</span>
                  <h6>{{ user.birthdate | moment('DD MMMM YYYY') }}</h6>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/gender.png" width="20" alt="date" class="mr-2">Gender</span>
                  <h6>{{ user.gender }}</h6>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/Email.png" width="20" alt="date" class="mr-2">Email</span>
                  <h6>{{ user.email }}</h6>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/Bank.png" width="20" alt="date" class="mr-2">Payroll</span>
                  <h6>{{ user.payroll }}</h6>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/phone.png" width="20" alt="date"
                             class="mr-2">Nomor telepon</span>
                  <h6>{{ user.mobilephoneno }}</h6>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/pin.png" width="20" alt="date" class="mr-2">Alamat</span>
                  <h6>{{ user.address }}</h6>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/Iuran.png" width="20" alt="date" class="mr-2">Saldo Iuran Pokok</span>
                  <h6>Rp. {{ currency(user.saldoiuranwajib) }}</h6>
                </div>
                <div class="account-field">
                  <span><img src="~@/assets/images/icons/Join.png" width="20" alt="date" class="mr-2">Tanggal Bergabung</span>
                  <h6>{{ user.joindate | moment('DD-MMM-YYYY') }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="container text-center mt-5">
            <v-btn class="primary" rounded @click="showModal">Ubah Password</v-btn> <v-btn rounded class="warning" @click="onLogout">Logout</v-btn>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="my-modal" hide-footer title="Ubah Password">
      <div class="d-block text-center">
        <h6>Silahkan ubah password Anda yang baru</h6>
        <form class="m-5" role="form" @submit.prevent="handleChangePassword">
          <div class="form-group text-left">
            <label for="">Password Lama</label>
            <input type="password" v-model="oldPassword" class="form-control" placeholder="Password lama">
          </div>
          <div class="form-group text-left">
            <label for="">Password Baru</label>
            <input type="password" v-model="newPassword" class="form-control" placeholder="Password baru">
          </div>
          <div class="form-group text-left">
            <label for="">Konfirmasi Password Baru</label>
            <input type="password" v-model="rePassword" class="form-control" placeholder="Konfirmasi Password baru">
          </div>
          <button type="submit" class="btn loan-btn-primary w-50">Ubah Password</button>
        </form>
      </div>
    </b-modal>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";
import UserService from "../services/user.service";
import Helper from "../helper/index";

export default {
  name: "Account",
  components: {Footer},
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      rePassword: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    }
  },
  methods: {
    onLogout() {
      this.$swal.fire({
        icon: 'question',
        text: 'Apakah yakin ingin logout ?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('auth/logout');
        }
      })
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.user.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleChangeProfile() {
      let params = {
        images: this.user.image
      }
      let loading = this.$loading.show();
      UserService.postChangeProfile(this.user.id, params).then(response => {
        if (response.code === 200) {
          loading.hide();
          this.hideModal();
          this.$swal.fire(
              'Success',
              'Foto profile anda berhasil dirubah',
              'success'
          )
        } else {
          loading.hide();
          this.$swal.fire(
              'Alert!',
              response.message,
              'error'
          )
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    handleChangePassword() {
      if (this.newPassword === this.rePassword) {
        let params = {
          "id": this.user.id,
          "old_password": this.oldPassword,
          "new_password": this.newPassword,
          'password_confirmation': this.rePassword
        }
        let loading = this.$loading.show();
        UserService.postChangePassword(params).then(response => {
          if (response.code === 200) {
            loading.hide();
            this.hideModal();
            this.$swal.fire({
              icon: 'success',
              text: 'Password and berhasil dirubah \n Silahkan login kembali',
              confirmButtonText: `Logout`
            }).then((result) => {
              if (result.isConfirmed) {
                this.$store.dispatch('auth/logout');
              }
            })
          } else {
            loading.hide();
            this.$swal.fire(
                'Alert!',
                response.status,
                'error'
            )
          }
        })
      } else {
        this.$swal.fire(
            'Alert !',
            'Password dan repasswod tidak sama !',
            'warning'
        )
      }
    },
    currency(amount) {
      return Helper.toRupiah(amount)
    }
  }
}
</script>

<style scoped>
.account {
  margin-top: 100px;
}

.account .image-upload > input {
  display: none;
}

.account-image .avatar img {
  background-color: #1E0E62;
  width: 150px;
  border-radius: 360px;
}

.account-image h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #1E0E62;
}

.account-image h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}

.account-detail {
  padding: 50px;
}

.account-detail .account-field {
  margin: 20px;
}

.account-detail .account-field span {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1E0E62;
}

.account-detail .account-field h6 {
  margin-top: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
}
</style>