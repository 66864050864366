<template>
  <div class="container">
    <div class="mt-5 mb-5">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-left">
          <div class="m-5">
            <h2>Anda mempunyai {{ poin > 0 ? poin : 0 }} poin</h2>
            <h6 class="mt-4">
              Poin Anda dapat digunakan untuk redeem dengan promo yang ada di toko fisik Bakti Fajar Idaman yang
              berlokasi di Head Office BFI Finance
            </h6>
            <div class="mt-5">
              <router-link to="/history-point" class="btn loan-btn-primary">Riwayat Penukaran</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 poin-icon">
          <div class="centered">
            <img src="~@/assets/images/Poin.png" width="400px" class="m-5" alt="">
            <div class="centered">
              <h1>{{ poin > 0 ? poin : 0 }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPoin",
  props: {poin: Number}
}
</script>

<style scoped>
.poins h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: -1px;
  color: #1E0E62;
}

.poins h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: rgba(21, 20, 57, 0.4);
  mix-blend-mode: normal;
  opacity: 0.7;
}

.poins .container {
  position: relative;
  text-align: center;
  color: white;
}

.poins .poin-icon .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.poins .poin-icon .centered h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 90px;
  line-height: 174px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #F57F17;
}

</style>