<template>
  <div class="about">
    <div class="container text-center">
      <h3>Tentang Kami</h3>
      <p class="text-left mt-5">
        Koperasi Bakti Fajar Idaman ("Koperasi BFI") berdiri sejalan dengan beroperasinya PT BFI Finance Indonesia.
      </p>
      <p class="text-left">
        Koperasi ini merupakan wadah bagi karyawan BFI untuk berkolaborasi dan bersinergi dalam membangun kesejahteraan
        bersama bagi anggotanya melalui program pinjaman sosial dan usaha pemenuhan kebutuhan sehari-hari karyawan.
      </p>
      <h2 class="text-center mt-5">Visi dan Misi</h2>
      <div class="container text-left">
        <h5>Visi</h5>
        <p>”Menjadi mitra kerja yang solutif dalam memberikan kemudahan akses pinjaman dan kesejahteraan bagi
          anggota”</p>

        <h5>Misi</h5>
        <ol>
          <li>Menyelenggarakan pelayanan prima kepada anggota sesuai jatidiri koperasi.</li>
          <li>Menjalankan kegiatan usaha simpan pinjam dengan efektif, efisien dan transparan.</li>
          <li>Menjalin kerjasama usaha dengan berbagai pihak, untuk meningkatkan peran ekonomi koperasi bagi anggota dan
            masyarakat.
          </li>
        </ol>
      </div>
      <p class="text-left mt-5">Koperasi ini dari awal, memang didesain untuk kepentingan terbatas dari anggota untuk
        anggota melalui kegiatan simpan dan pinjam untuk keperluan pribadi anggota. Tetapi dengan berjalannya waktu,
        kegiatan dari koperasi ini berkembang untuk pemenuhan kebutuhan sehari-hari anggota melalui toko koperasi
        BFIMart.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "About",
}
</script>

<style scoped>
.about {
  margin-top: 100px;
}

.about h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #1E0E62;
}
</style>