<template>
  <!-- Blog -->
  <section class="about">
    <div class="container">
      <div class="row mt-5">
        <div class="col-lg-12 col-md-12 text-center">
          <h3 class="mt-5" color="#1E0E62">Blog</h3>
          <p class="des">Baca blog terbaru dari kami terkait produk baru, keuntungan <br>
            dan sebagainya yang kami rangkum khusus untuk Anda</p>
        </div>
      </div>
      <v-row>
        <v-col cols="4" md="4" v-for="item in newest" :key="item.id">
          <v-img :src="item.ImagesPath" class="align-end rounded-lg">
            <v-card dark color="#0000005e">
              <v-card-subtitle class="text-left">
                <v-chip color="primary">{{ item.Tags }}</v-chip>
              </v-card-subtitle>
              <v-card-text>
                <div class="text-white">
                  {{ item.TitleName }}
                </div>
              </v-card-text>
            </v-card>
          </v-img>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import router from "../router";

export default {
  name: "Blog",
  computed: {
    newest() {
      return this.$store.state.blog.newest;
    }
  },
  methods: {
    handleRead(slug) {
      sessionStorage.setItem('slug', JSON.stringify(slug));
      this.$store.dispatch('blog/setSlug', slug).then(() => {
        router.push('/blog/slug');
      });
    }
  },
  mounted() {
    this.$store.dispatch('blog/getNewest');
  }
}
</script>

<style scoped>
.body-blog .card-img-top {
  border-radius: 10px;
  height: 200px;
}
</style>