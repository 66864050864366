import Api from '@/api'
import Host from '@/api/host'

class OtpService {
    getOTP(nik) {
        return Api.doGet(Host.LOAN_V1 + '/otp/' + nik);
    }

    postVerify(params) {
        return Api.doPost(Host.LOAN_V1 + '/otp/verifikasi', params);
    }

    postResetPassowrd(params) {
        return Api.doPost(Host.LOAN_V1 + '/reset-password', params);
    }
}

export default new OtpService();