<template>
  <div class="produk">
    <!-- <NavBar/> -->
    <div class="about">
      <section class="mt-16">
        <div class="container">
          <v-row>
            <v-col cols="12" class="text-center">
              <h3 class="mt-5" color="#1E0E62">Informasi Promo Terbaru</h3>
            </v-col>
            <v-col md="12">
              <embed width="100%" type="text/html" height="100%" src="https://bfm.baktifajar.co.id/solusikita" allowfullscreen/>
            </v-col>
          </v-row>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Promo",
  data() {
    return {
    }
  },
}
</script>
