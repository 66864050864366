<template>
  <div class="loan">
    <!-- <NavBar /> -->
    <div class="home">
      <div v-if="user.email == null || user.mobilephoneno == null">
        <div class="row mt-5 ml-5 mr-5">
          <h1 class="text-center">Selamat Datang di Solusi Kita</h1>
          <div class="mt-5 mb-5">
            <div class="text-left">
              <h2>Update Email dan No Telpon</h2>
            </div>
            <div class="notification-detail">
              <form @submit.prevent="handleSubmit">
                <div class="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    placeholder="Email"
                    v-model="email"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">No Telpon</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="No Telpon"
                    v-model="mobilephoneno"
                    @keypress="onlyNumber"
                  />
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <Saldo />
        <NoLoan />
        <!-- <Amortisasi :nik="user.NIK" /> -->
        <my-loan :nik="user.NIK" />
        <loan-history :nik="user.NIK" />
        
      </div>
    </div>
    <!-- {{user.email}}
    {{user.mobilephoneno}} -->
    <Footer />
  </div>
</template>

<script>
// import router from "../router";
import Footer from "../components/Footer";
import Saldo from "../components/home/Saldo.vue";
// import NavBar from "../components/navigation/NavBar";
import MyLoan from "../components/home/MyLoan.vue";
import LoanHistory from "../components/home/LoanHistory";
import NoLoan from "../components/home/NoLoan";
// import Amortisasi from '../components/home/Amortisasi.vue'
import UserService from "../services/user.service";

export default {
  name: "Home",
  components: { NoLoan, LoanHistory, MyLoan, Saldo, Footer },
  data() {
    return {
      email: "",
      mobilephoneno: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  methods: {
    onLogout() {
      this.$swal
        .fire({
          icon: "question",
          text: "Apakah yakin ingin logout ?",
          showDenyButton: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
      }
    },
    getStatus() {
      this.$store.dispatch("loan/getStatusPelunasan", this.user.NIK)
    },
    handleSubmit() {
      let params = {
        email: this.email,
        mobilephoneno: this.mobilephoneno,
      };
      let loading = this.$loading.show();
      UserService.postupdateData(this.user.id, params).then((response) => {
        if (response.code === 200) {
          loading.hide();
          // this.hideModal();
          this.$swal
            .fire({
              icon: "success",
              text: "Update Data Email dan no telpon berhasil dilakukan\n Silahkan login kembali",
              confirmButtonText: `Logout`,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$store.dispatch("auth/logout");
              }
            });
        } else {
          loading.hide();
          this.$swal.fire("Alert!", response.message, "error");
        }
      });
    },
  },
  created() {
    this.getStatus();
    this.$store.dispatch("loan/getHistories", this.user.NIK);
    this.$store.dispatch("loan/getInquiries", this.user.NIK);
    this.$store.dispatch("loan/getAmortisasi", this.user.NIK);
    this.$store.dispatch("loan/getStepper", this.user.companyID);
    
  },
};
</script>

<style scoped>
</style>

