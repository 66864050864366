<template>
  <v-row>
    <v-col md="4" sm="4" v-for="item in records" :key="item.id" @click="handleRead(item.id)">
      <v-hover
        v-slot="{ hover }">
        <v-card 
        class="mx-auto pb-4"
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        max-width="400"
        height="350">
          <v-img :src="item.ImagesPath" alt="item.id">
            <v-chip class="ma-2">{{ item.Tags }}</v-chip>
          </v-img>
          <v-card-text>{{ item.TitleName }}</v-card-text>
          <v-card-subtitle style="margin-top: auto !important; margin-bottom: 0px;">
            Penulis : {{ item.WritenBy }} <span>{{ item.Createdate | moment('DD, MM, YYYY') }}</span>
          </v-card-subtitle>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
  <!-- <div class="row">
    <div class="col-sm-4 col-md-4" v-for="item in records" :key="item.id">
      <div class="card-image" @click="handleRead(item.id)">
        <img :src="item.ImagesPath" alt="item.id">
      </div>
      <div class="card-body-image">
        <button class="btn btn-tag-archive w-auto">{{ item.Tags }}</button>
        <h3>{{ item.TitleName }} </h3>
        <p>Penulis : {{ item.WritenBy }} <span>{{ item.Createdate | moment('DD, MM, YYYY') }}</span></p>
      </div>
    </div>
  </div> -->
</template>

<script>
import router from "../../router";

export default {
  name: "Thumbnail",
  props: {
    records: Array
  },
  methods: {
    handleRead(slug) {
      sessionStorage.setItem('slug',JSON.stringify(slug));
      this.$store.dispatch('blog/setSlug',slug);
      router.push('/blog/slug');
    }
  }
}
</script>

<style scoped>
.card-image img{
  border-radius: 12px;
  width: 105%;
}
</style>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>