<template>
  <div class="loan">
    <!-- <NavBar/> -->
    <div class="tnc">
      <div class="container text-left">
        <h1 class="text-center mb-5">Syarat dan Ketentuan</h1>
        <ol class="text-left">
          <li>Setiap karyawan BFI/FIT/SIP yang telah diangkat atau menjadi karyawan tetap secara otomatis menjadi
            anggota Koperasi sesuai dengan kesepakatan dalam surat pengangkatan karyawan tetap.
          </li>
          <li>Telah dilakukan pemotongan iuran wajib Koperasi yang besarnya ditentukan sesuai dengan Job Grade (JG)
            anggota.
          </li>
          <li>Iuran wajib Koperasi dipotong secara bulanan sesuai dengan periode penggajian di MTH25.</li>
          <li>Akumulasi iuran wajib Koperasi tersebut menjadi hak anggota dan merupakan saldo yang dapat digunakan
            sebagai pertimbangan menentukan besarnya pinjaman koperasi.
          </li>
          <li>Masih aktif bekerja sebagai karyawan di induk perusahaan / anak / afiliasi perusahaan</li>
        </ol>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../components/Footer";
// import NavBar from "../components/navigation/NavBar";

export default {
  name: "TermsAndConditions",
  components: { Footer }
}
</script>

<style scoped>
.tnc {
  margin-top: 100px;
}

.tnc p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
}

.tnc h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #1E0E62;
}

.tnc ol li {
  margin-top: 50px;
}
</style>