<template>
  <!-- Footer -->
  <footer class="bg-ico">
    <v-img src="~@/assets/images/icon-bg2.png" class="hidden-sm-and-down" style="position: absolute;width: 40%;"></v-img>
    <v-img src="~@/assets/images/gedung.png" class="hidden-sm-and-down" style="position: absolute; width: 40%; right: 0;"></v-img>
    <div class="container">
      <div class="row footer lokasi-kami">
        <div class="col-xl-3 col-md-3 text-left">
          <h3><img src="~@/assets/images/logo.png" alt="" width="25px" class="mr-1">Bakti Fajar Idaman</h3>
          <p> Bakti Fajar Idaman adalah Koperasi Karyawan PT BFI Finance Tbk yang
            didirikan dengan tujuan untuk
            meningkatkan kesejahteraan karyawan dan taraf hidup anggota. Koperasi Bakti Fajar
            Idaman didirikan pada tanggal xxxx dan di sahkan dengan badan hukum.</p>
        </div>
        <div class="col-xl-3 col-md-3">
          <ul style="list-style:none;">
            <h4 class="text-left">Layanan</h4>
            <li v-if="!isLogin" class="mt-5 text-left">
              <router-link link to="/about">Tentang kami</router-link>
            </li>
            <li v-if="!isLogin" class="mt-3 text-left">
              <router-link link to="/organisasi">
                Struktur Organisasi
              </router-link>
            </li>
            <li v-if="isLogin" class="mt-3 text-left">
              <router-link link to="/application-form">Pinjaman</router-link>
            </li>
            <li v-if="isLogin" class="mt-3 text-left">
              <router-link link to="/poins">Poin</router-link>
            </li>
            <li class="mt-3 text-left">
              <router-link link to="/blog">Blog</router-link>
            </li>
            <li v-if="isLogin" class="mt-3 text-left">
              <router-link link to="/faq">FAQ</router-link>
            </li>
            <li class="mt-3 text-left">
              <router-link link to="/terms-and-conditions">Syarat dan Ketentuan</router-link>
            </li>
          </ul>
        </div>
        <div class="col-xl-3 col-md-3 text-left">
          <h4>Lokasi Kami</h4>
          <p class="mt-5">BFI Tower <br>
            Sunburst CBD Lot. 1.2 <br>
            Jl. Kapt. Soebijanto <br> Djojohadikusumo <br>
            BSD City - Tangerang Selatan 15322</p>
        </div>
        <div class="col-xl-3 col-md-3 text-left">
          <h4 class="text-center" style="margin-left: -25px">Hubungi Kami</h4>
          <ul style="list-style:none;">
            <li class="mt-5 text-left"><a href="#"><i class="sosmed-icon"><img src="~@/assets/images/icons/IG.png"
                                                                               alt="sosmes-icons"/>
              @baktifajaridaman</i></a></li>
            <li class="mt-2 text-left"><a href="#"><i class="sosmed-icon"><img src="~@/assets/images/icons/WhatsApp.png"
                                                                               alt="sosmes-icons"/>021 296 50555</i></a>
            </li>
            <li class="mt-2 text-left"><a href="#"><i class="sosmed-icon"><img src="~@/assets/images/icons/FB.png"
                                                                               alt="sosmes-icons"/>Bakti Fajar
              Idaman</i></a></li>
            <li class="mt-2 text-left"><a href="mailto:email:loan.koperasi@bfi.co.id?subject=News&body=New%20plugin"><i
                class="sosmed-icon"><img src="~@/assets/images/icons/Email.png" alt="sosmes-icons"/>loan.koperasi@baktifajar.co.id</i></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row footer-bottom m-5">
        <div class="col-lg-12 col-md-12 text-center"><span>
                       © 2022 Bakti Fajar Idaman. All rights reserved
                    </span></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    isLogin() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
}
</script>

<style scoped>
.bg-ico {
  left: 0;
  bottom: 0;
  width: 100%;
  /* color: white; */
  text-align: center;
  /* background: url('~@/assets/images/icon-bg.png') no-repeat; */
  background-color: #f1f1f1;
  background-size: inherit;
  height: 100%;
  /* opacity: 0.1; */
}

.lokasi-kami p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px; /* or 171% */
  font-feature-settings: 'liga' off;
  color: #061B43 !important;  
  z-index: 100;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .bg-ico {
    margin-top: 100px !important;
    /* background: url('~@/assets/images/icon-bg.png') no-repeat; */
    background-color: #f1f1f1;
    background-size: inherit;
    height: autopx;
    opacity: 0.8;
  }
}
</style>