import axios from "axios";
import Host from '@/api/host'
import authHeader from "@/api/header";
import router from "../router";
import alerts from '@/alerts/alert'

class AuthService {
    login(payload) {
        return axios
            .post(Host.LOAN_V1 + '/web/login', payload)
            .then(response => {
                console.log('response = ' + JSON.stringify(response.data));
                if (response.data.data.access_token) {
                    localStorage.setItem('LOAN_SESSION', JSON.stringify(response.data.data));
                    router.go({ name: 'Home' })
                }
                // return response.data;
            }).catch(error => {
                console.log('error = ' + JSON.stringify(error.response.data));
                alerts.sweetalertToast('error', error.response.data.error)
              });
    }

    logout() {
        axios.post(Host.LOAN_V1 + '/logout', {headers: authHeader()}).then(() => {
            localStorage.removeItem('LOAN_SESSION');
            router.push("/");
            location.reload();
        }, () => {
            localStorage.removeItem('LOAN_SESSION');
            router.push("/");
            location.reload();
        });
    }

    refreshToken() {
        return axios.post(Host.LOAN_V1 + '/refresh', "", {headers: authHeader()})
            .then(response => {
                if (response.data.data.access_token) {
                    localStorage.setItem('LOAN_SESSION', JSON.stringify(response.data.data));
                }
                return response.data;
            }, () => {
                this.logout();
            });
    }
}

export default new AuthService();