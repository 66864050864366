<template>
  <div class="loan">
    <!-- <nav-bar/> -->
    <div class="struktur-organisasi">
      <section class="team">
        <div class="container">
          <div class="row mt-5">
            <div class="col-lg-12 col-md-12 col-12 text-center">
              <h3 class="mt-5" color="#1E0E62">Struktur Organisasi <br> Bakti Fajar Idaman</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-12" v-for="item in ocharts" :key="item.id">
              <div class="team-body text-center">
                <img :src="item.ImagesPath" class="card-img-top" style="border-radius: 10px;" alt="avatar"/>
                <h4>{{ item.PIC }}</h4>
                <p>{{ item.TitleName }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import NavBar from "../components/navigation/NavBar";
import Footer from "../components/Footer";

export default {
  name: "StrukturOrganisasi",
  components: {Footer},
  computed: {
    ocharts() {
      return this.$store.state.ochart.data;
    }
  },
  mounted() {
    this.$store.dispatch('ochart/getAll');
  }
}
</script>

<style scoped>
.card-img-top {
  width: 300px;
  margin-bottom: 20px;
}
</style>