import Api from '@/api'
import Host from '@/api/host'

class BlogService {
    getAll() {
        return Api.doGet(Host.LOAN_V1 + '/blog');
    }

    getNewest() {
        return Api.doGet(Host.LOAN_V1 + '/blog/newest');
    }

    getArchive() {
        return Api.doGet(Host.LOAN_V1 + '/blog/archive');
    }

    getCategories() {
        return Api.doGet(Host.LOAN_V1 + '/blog/category');
    }

    getPagination(searchBy, searchParam, limit, page) {
        let params = {
            limit: limit,
            searchBy: searchBy,
            searchParam: searchParam,
            page: page
        }
        return Api.doGet(Host.LOAN_V1 + '/blog/paginate', params);
    }

    getShow(id) {
        return Api.doGet(Host.LOAN_V1 + '/blog/' + id);
    }

    getComment(id) {
        return Api.doGet(Host.LOAN_V1 + '/blog/commentview/' + id);
    }

    postComment(params) {
        return Api.doPost(Host.LOAN_V1 + '/blog/comment', params);
    }
}

export default new BlogService();