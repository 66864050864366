import PointService from "@/services/point.service";

const product = {
    namespaced: true,
    state: {
        points: [],
        point: []
    },
    actions: {
        getAll({commit}) {
            PointService.getPoint().then((response) => {
                commit('SET_RECORDS', response.data);
            })
        },

        getHistory({commit}, id) {
            PointService.getHistoryPoint(id).then((response) => {
                commit('SET_RECORD', response.data);
            })
        }
    },
    mutations: {
        SET_RECORDS(state, data) {
            state.points = data;
        },
        SET_RECORD(state, data) {
            state.point = data;
        }
    }
};

export default product;