import FaqService from "@/services/faq.service";

const faq = {
    namespaced: true,
    state: {
        data: [],
        detail:[]
    },
    actions: {
        getAll({commit}) {
            FaqService.getAll().then((response) => {
                commit('SET_RECORDS', response.data);
            })
        },
        getDetail({commit},param) {
            FaqService.postDetail(param).then((response) => {
                commit('SET_DETAIL', response.data);
            })
        }
    },
    mutations: {
        SET_RECORDS(state, data) {
            state.data = data;
        },
        SET_DETAIL(state, data) {
            state.detail = data;
        }
    }
};

export default faq;