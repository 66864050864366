import Vue from 'vue'
import App from './App.vue'
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "bootstrap";
import './app.scss'
import retina from 'retinajs';
import VueRetina from 'vue-retina';
import router from './router'
import store from './store';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vuetify from './plugins/vuetify'

Vue.use(VueSweetalert2);
Vue.use(Loading, {
  // props
  color: '#FFEB3B',
  height: 70,
  zIndex: 999999999
}, {
  // slots
})

Vue.use(VueMoment);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
window.$ = $;
Vue.use(Popper);
Vue.use(VueRetina, {retina})
Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
